.ag-theme-material {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	font: 400 13px "Roboto", sans-serif; }
	.ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected {
	  border-bottom: 2px solid #3f51b5; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-1 {
	  padding-left: 26px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-1 {
	  padding-right: 26px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-1 {
	  padding-left: 42px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-1 {
	  padding-right: 42px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-2 {
	  padding-left: 52px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-2 {
	  padding-right: 52px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-2 {
	  padding-left: 84px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-2 {
	  padding-right: 84px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-3 {
	  padding-left: 78px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-3 {
	  padding-right: 78px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-3 {
	  padding-left: 126px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-3 {
	  padding-right: 126px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-4 {
	  padding-left: 104px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-4 {
	  padding-right: 104px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-4 {
	  padding-left: 168px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-4 {
	  padding-right: 168px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-5 {
	  padding-left: 130px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-5 {
	  padding-right: 130px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-5 {
	  padding-left: 210px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-5 {
	  padding-right: 210px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-6 {
	  padding-left: 156px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-6 {
	  padding-right: 156px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-6 {
	  padding-left: 252px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-6 {
	  padding-right: 252px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-7 {
	  padding-left: 182px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-7 {
	  padding-right: 182px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-7 {
	  padding-left: 294px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-7 {
	  padding-right: 294px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-8 {
	  padding-left: 208px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-8 {
	  padding-right: 208px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-8 {
	  padding-left: 336px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-8 {
	  padding-right: 336px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-9 {
	  padding-left: 234px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-9 {
	  padding-right: 234px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-9 {
	  padding-left: 378px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-9 {
	  padding-right: 378px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-10 {
	  padding-left: 260px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-10 {
	  padding-right: 260px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-10 {
	  padding-left: 420px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-10 {
	  padding-right: 420px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-11 {
	  padding-left: 286px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-11 {
	  padding-right: 286px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-11 {
	  padding-left: 462px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-11 {
	  padding-right: 462px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-12 {
	  padding-left: 312px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-12 {
	  padding-right: 312px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-12 {
	  padding-left: 504px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-12 {
	  padding-right: 504px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-13 {
	  padding-left: 338px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-13 {
	  padding-right: 338px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-13 {
	  padding-left: 546px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-13 {
	  padding-right: 546px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-14 {
	  padding-left: 364px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-14 {
	  padding-right: 364px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-14 {
	  padding-left: 588px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-14 {
	  padding-right: 588px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-15 {
	  padding-left: 390px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-15 {
	  padding-right: 390px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-15 {
	  padding-left: 630px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-15 {
	  padding-right: 630px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-16 {
	  padding-left: 416px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-16 {
	  padding-right: 416px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-16 {
	  padding-left: 672px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-16 {
	  padding-right: 672px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-17 {
	  padding-left: 442px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-17 {
	  padding-right: 442px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-17 {
	  padding-left: 714px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-17 {
	  padding-right: 714px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-18 {
	  padding-left: 468px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-18 {
	  padding-right: 468px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-18 {
	  padding-left: 756px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-18 {
	  padding-right: 756px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-19 {
	  padding-left: 494px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-19 {
	  padding-right: 494px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-19 {
	  padding-left: 798px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-19 {
	  padding-right: 798px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-20 {
	  padding-left: 520px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-20 {
	  padding-right: 520px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-20 {
	  padding-left: 840px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-20 {
	  padding-right: 840px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-21 {
	  padding-left: 546px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-21 {
	  padding-right: 546px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-21 {
	  padding-left: 882px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-21 {
	  padding-right: 882px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-22 {
	  padding-left: 572px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-22 {
	  padding-right: 572px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-22 {
	  padding-left: 924px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-22 {
	  padding-right: 924px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-23 {
	  padding-left: 598px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-23 {
	  padding-right: 598px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-23 {
	  padding-left: 966px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-23 {
	  padding-right: 966px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-24 {
	  padding-left: 624px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-24 {
	  padding-right: 624px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-24 {
	  padding-left: 1008px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-24 {
	  padding-right: 1008px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-25 {
	  padding-left: 650px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-25 {
	  padding-right: 650px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-25 {
	  padding-left: 1050px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-25 {
	  padding-right: 1050px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-26 {
	  padding-left: 676px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-26 {
	  padding-right: 676px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-26 {
	  padding-left: 1092px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-26 {
	  padding-right: 1092px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-27 {
	  padding-left: 702px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-27 {
	  padding-right: 702px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-27 {
	  padding-left: 1134px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-27 {
	  padding-right: 1134px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-28 {
	  padding-left: 728px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-28 {
	  padding-right: 728px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-28 {
	  padding-left: 1176px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-28 {
	  padding-right: 1176px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-29 {
	  padding-left: 754px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-29 {
	  padding-right: 754px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-29 {
	  padding-left: 1218px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-29 {
	  padding-right: 1218px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-30 {
	  padding-left: 780px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-30 {
	  padding-right: 780px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-30 {
	  padding-left: 1260px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-30 {
	  padding-right: 1260px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-31 {
	  padding-left: 806px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-31 {
	  padding-right: 806px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-31 {
	  padding-left: 1302px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-31 {
	  padding-right: 1302px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-32 {
	  padding-left: 832px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-32 {
	  padding-right: 832px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-32 {
	  padding-left: 1344px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-32 {
	  padding-right: 1344px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-33 {
	  padding-left: 858px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-33 {
	  padding-right: 858px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-33 {
	  padding-left: 1386px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-33 {
	  padding-right: 1386px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-34 {
	  padding-left: 884px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-34 {
	  padding-right: 884px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-34 {
	  padding-left: 1428px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-34 {
	  padding-right: 1428px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-35 {
	  padding-left: 910px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-35 {
	  padding-right: 910px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-35 {
	  padding-left: 1470px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-35 {
	  padding-right: 1470px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-36 {
	  padding-left: 936px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-36 {
	  padding-right: 936px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-36 {
	  padding-left: 1512px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-36 {
	  padding-right: 1512px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-37 {
	  padding-left: 962px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-37 {
	  padding-right: 962px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-37 {
	  padding-left: 1554px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-37 {
	  padding-right: 1554px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-38 {
	  padding-left: 988px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-38 {
	  padding-right: 988px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-38 {
	  padding-left: 1596px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-38 {
	  padding-right: 1596px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-39 {
	  padding-left: 1014px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-39 {
	  padding-right: 1014px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-39 {
	  padding-left: 1638px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-39 {
	  padding-right: 1638px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-40 {
	  padding-left: 1040px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-40 {
	  padding-right: 1040px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-40 {
	  padding-left: 1680px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-40 {
	  padding-right: 1680px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-41 {
	  padding-left: 1066px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-41 {
	  padding-right: 1066px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-41 {
	  padding-left: 1722px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-41 {
	  padding-right: 1722px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-42 {
	  padding-left: 1092px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-42 {
	  padding-right: 1092px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-42 {
	  padding-left: 1764px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-42 {
	  padding-right: 1764px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-43 {
	  padding-left: 1118px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-43 {
	  padding-right: 1118px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-43 {
	  padding-left: 1806px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-43 {
	  padding-right: 1806px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-44 {
	  padding-left: 1144px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-44 {
	  padding-right: 1144px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-44 {
	  padding-left: 1848px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-44 {
	  padding-right: 1848px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-45 {
	  padding-left: 1170px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-45 {
	  padding-right: 1170px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-45 {
	  padding-left: 1890px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-45 {
	  padding-right: 1890px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-46 {
	  padding-left: 1196px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-46 {
	  padding-right: 1196px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-46 {
	  padding-left: 1932px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-46 {
	  padding-right: 1932px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-47 {
	  padding-left: 1222px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-47 {
	  padding-right: 1222px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-47 {
	  padding-left: 1974px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-47 {
	  padding-right: 1974px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-48 {
	  padding-left: 1248px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-48 {
	  padding-right: 1248px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-48 {
	  padding-left: 2016px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-48 {
	  padding-right: 2016px; }
	.ag-theme-material .ag-ltr .ag-toolpanel-indent-49 {
	  padding-left: 1274px; }
	.ag-theme-material .ag-rtl .ag-toolpanel-indent-49 {
	  padding-right: 1274px; }
	.ag-theme-material .ag-ltr .ag-row-group-indent-49 {
	  padding-left: 2058px; }
	.ag-theme-material .ag-rtl .ag-row-group-indent-49 {
	  padding-right: 2058px; }
	.ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
	  margin-left: 42px; }
	.ag-theme-material .ag-rtl .ag-row-group-leaf-indent {
	  margin-right: 42px; }
	.ag-theme-material .ag-rtl .ag-cell-first-right-pinned {
	  border-left: 1px solid #e0e0e0; }
	.ag-theme-material .ag-ltr .ag-cell-first-right-pinned {
	  border-left: 1px solid #e0e0e0; }
	.ag-theme-material .ag-rtl .ag-cell-last-left-pinned {
	  border-right: 1px solid #e0e0e0; }
	.ag-theme-material .ag-ltr .ag-cell-last-left-pinned {
	  border-right: 1px solid #e0e0e0; }
	.ag-theme-material .ag-value-change-delta {
	  padding-right: 2px; }
	.ag-theme-material .ag-value-change-delta-up {
	  color: #43a047; }
	.ag-theme-material .ag-value-change-delta-down {
	  color: #e53935; }
	.ag-theme-material .ag-value-change-value {
	  background-color: transparent;
	  border-radius: 1px;
	  padding-left: 1px;
	  padding-right: 1px;
	  transition: background-color 1s; }
	.ag-theme-material .ag-value-change-value-highlight {
	  background-color: #00acc1;
	  transition: background-color 0.1s; }
	.ag-theme-material .ag-header {
	  color: rgba(0, 0, 0, 0.54);
	  font: 700 12px "Roboto", sans-serif; }
	.ag-theme-material .ag-header-row {
	  border-bottom: 1px solid #e0e0e0;
	  box-sizing: border-box; }
	.ag-theme-material .ag-row {
	  border-bottom: 1px solid #e0e0e0;
	  box-sizing: border-box; }
	.ag-theme-material .ag-row-hover {
	  background-color: #eee; }
	.ag-theme-material .ag-numeric-cell {
	  text-align: right; }
	.ag-theme-material .ag-header-cell-label {
	  display: flex;
	  float: left;
	  height: 100%;
	  width: calc(100% - 18px); }
	  .ag-theme-material .ag-header-cell-label span {
		height: 100%; }
	  .ag-theme-material .ag-header-cell-label > span {
		float: left; }
	  .ag-theme-material .ag-header-cell-label .ag-header-icon {
		background-position-y: 20px;
		background-size: 14px 14px;
		height: 100%;
		margin: 0;
		margin-left: 8px;
		opacity: 0.87; }
	  .ag-theme-material .ag-header-cell-label .ag-header-cell-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; }
	.ag-theme-material .ag-numeric-header .ag-header-cell-label {
	  flex-direction: row-reverse;
	  float: right; }
	  .ag-theme-material .ag-numeric-header .ag-header-cell-label > span {
		float: right; }
	.ag-theme-material .ag-numeric-header .ag-header-cell-menu-button {
	  float: left; }
	.ag-theme-material .ag-header-group-text {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap; }
	.ag-theme-material .ag-header-cell,
	.ag-theme-material .ag-header-group-cell {
	  line-height: 56px;
	  padding-left: 24px;
	  padding-right: 24px; }
	.ag-theme-material .ag-cell {
	  line-height: 46px;
	  border: 1px solid transparent;
	  padding-left: 23px;
	  padding-right: 23px; }
	.ag-theme-material .ag-row-drag {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMyA2aDEydjJIM3ptMCA0aDEydjJIM3oiIGZpbGw9IiMzMzMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  opacity: 0.87;
	  background-position-x: left;
	  background-position-y: 6px;
	  float: left;
	  height: 100%;
	  width: 42px; }
	.ag-theme-material .ag-column-drag {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMyA2aDEydjJIM3ptMCA0aDEydjJIM3oiIGZpbGw9IiMzMzMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  opacity: 0.87;
	  width: 18px;
	  background-position-x: left;
	  background-position-y: 8px !important;
	  height: 100%;
	  min-width: 26px; }
	.ag-theme-material .ag-row-dragging {
	  opacity: 0.5;
	  z-index: 10000; }
	.ag-theme-material .ag-cell-focus {
	  border: 1px solid #3f51b5;
	  outline: initial; }
	.ag-theme-material .ag-header-cell-resize {
	  position: absolute;
	  right: -8px;
	  width: 16px;
	  z-index: 4; }
	  .ag-theme-material .ag-header-cell-resize::after {
		border-right: 1px solid #e0e0e0;
		box-sizing: content-box;
		content: "resize";
		display: block;
		height: 24px;
		margin-top: 16px;
		overflow: hidden;
		text-indent: 8px;
		width: 8px; }
	.ag-theme-material .ag-icon-aggregation {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTMgMTN2MWExIDEgMCAwIDEtMSAxSDVhMSAxIDAgMCAxLTEtMXYtMWwzLTQtMy00VjRhMSAxIDAgMCAxIDEtMWg3YTEgMSAwIDAgMSAxIDF2MUg2LjVsMi41NSAzLjRhMSAxIDAgMCAxIDAgMS4yTDYuNSAxM0gxM3oiIGZpbGw9IiMzMzMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-arrows {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy41IDYuNWgzdi0yaDJMOSAxIDUuNSA0LjVoMnYyem0tMSAxaC0ydi0yTDEgOWwzLjUgMy41di0yaDJ2LTN6TTE3IDlsLTMuNS0zLjV2MmgtMnYzaDJ2MkwxNyA5em0tNi41IDIuNWgtM3YyaC0yTDkgMTdsMy41LTMuNWgtMnYtMnoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-asc {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-checkbox-checked-readonly {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiMzMzMiIG9wYWNpdHk9Ii41Ii8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-checkbox-checked {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-checkbox-indeterminate-readonly {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyQy45IDAgMCAuOSAwIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlYyYzAtMS4xLS45LTItMi0yem0tMiAxMEg0VjhoMTB2MnoiIGZpbGw9IiMzMzMiIGZpbGwtb3BhY2l0eT0iLjUiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-checkbox-indeterminate {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyQy45IDAgMCAuOSAwIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlYyYzAtMS4xLS45LTItMi0yem0tMiAxMEg0VjhoMTB2MnoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-checkbox-unchecked-readonly {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMnYxNEgyVjJoMTR6bTAtMkgyQy45IDAgMCAuOSAwIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlYyYzAtMS4xLS45LTItMi0yeiIgZmlsbD0iIzMzMyIgZmlsbC1vcGFjaXR5PSIuNSIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-checkbox-unchecked {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMnYxNEgyVjJoMTR6bTAtMkgyQy45IDAgMCAuOSAwIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlYyYzAtMS4xLS45LTItMi0yeiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-column {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiAyaDR2Mkgyem0wIDRoNHYxMEgyeiIgZmlsbD0iIzMzMyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-columns {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMyA0aDN2Mkgzem01IDBoM3YySDh6bTUgMGgzdjJoLTN6TTMgOGgzdjJIM3ptNSAwaDN2Mkg4em01IDBoM3YyaC0zek0zIDEyaDN2Mkgzem01IDBoM3YySDh6bTUgMGgzdjJoLTN6IiBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-contracted {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEuNSAxMi41TDggOWwzLjUtMy41LTEtMUw2IDlsNC41IDQuNXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-copy {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMSAySDRhMSAxIDAgMCAwLTEgMXY5aDFWM2g3VjJ6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNiA0aDZhMSAxIDAgMCAxIDEgMXY5YTEgMSAwIDAgMS0xIDFINmExIDEgMCAwIDEtMS0xVjVhMSAxIDAgMCAxIDEtMXptMCAxdjloNlY1SDZ6Ii8+PC9nPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-cut {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy4zNDggNS45NDhjLjE2MS0uMzUuMjUyLS43MzUuMjUyLTEuMTQ4YTIuOCAyLjggMCAxIDAtNS42IDAgMi44IDIuOCAwIDAgMCAyLjggMi44Yy40MTMgMCAuNzk4LS4wOTEgMS4xNDgtLjI1Mkw3LjYgOWwtMS42NTIgMS42NTJBMi43MjggMi43MjggMCAwIDAgNC44IDEwLjRhMi44IDIuOCAwIDEgMCAwIDUuNiAyLjggMi44IDAgMCAwIDIuOC0yLjhjMC0uNDEzLS4wOTEtLjc5OC0uMjUyLTEuMTQ4TDkgMTAuNGw0LjkgNC45SDE2di0uN0w3LjM0OCA1Ljk0OHpNNC44IDYuMmExLjQgMS40IDAgMSAxIDAtMi44IDEuNCAxLjQgMCAwIDEgMCAyLjh6bTAgOC40YTEuNCAxLjQgMCAxIDEgMC0yLjggMS40IDEuNCAwIDAgMSAwIDIuOHpNOSA5LjM1QS4zNDcuMzQ3IDAgMCAxIDguNjUgOWMwLS4xOTYuMTU0LS4zNS4zNS0uMzUuMTk2IDAgLjM1LjE1NC4zNS4zNSAwIC4xOTYtLjE1NC4zNS0uMzUuMzV6bTQuOS02LjY1TDkuNyA2LjlsMS40IDEuNEwxNiAzLjR2LS43aC0yLjF6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-desc {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-expanded {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiAxMi41TDkuNSA5IDYgNS41bDEtMUwxMS41IDkgNyAxMy41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-eye-slash {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMi40NDkgMTIuNDVsLTEuMzg4LTEuMzg3YTIuOTE4IDIuOTE4IDAgMCAwLTQuMTI0LTQuMTI0TDUuNTUgNS41NUE2Ljg1IDYuODUgMCAwIDEgOSA0LjYyNSA2Ljg5OSA2Ljg5OSAwIDAgMSAxNS40MTcgOWE2LjkzNSA2LjkzNSAwIDAgMS0yLjk2OCAzLjQ1em0tLjk1NS40NkE2Ljg5OSA2Ljg5OSAwIDAgMSAyLjU4NCA5IDYuOTMzIDYuOTMzIDAgMCAxIDQuNzEgNi4xMjVMNi4zNTUgNy43N2EyLjkxOCAyLjkxOCAwIDAgMCAzLjg3NSAzLjg3NWwxLjI2NCAxLjI2NHoiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik0xMC4yNDIgMTAuMjM1TDcuNzY1IDcuNzU4QTEuNzQ0IDEuNzQ0IDAgMCAxIDkgNy4yNWMuOTY4IDAgMS43NS43ODIgMS43NSAxLjc1IDAgLjQ4Mi0uMTk0LjkxOC0uNTA4IDEuMjM1em0tLjkyNy40ODdhMS43NDggMS43NDggMCAwIDEtMi4wMzctMi4wMzdsMi4wMzcgMi4wMzd6Ii8+PHBhdGggZD0iTTMuMDcgOC40ODdoMTIuNDE3djFIMy4wN3oiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-eye {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSA0LjYyNUE2Ljg5OSA2Ljg5OSAwIDAgMCAyLjU4MyA5IDYuODk5IDYuODk5IDAgMCAwIDkgMTMuMzc1IDYuODk5IDYuODk5IDAgMCAwIDE1LjQxNyA5IDYuODk5IDYuODk5IDAgMCAwIDkgNC42MjV6bTAgNy4yOTJhMi45MTggMi45MTggMCAwIDEgMC01LjgzNCAyLjkxOCAyLjkxOCAwIDAgMSAwIDUuODM0ek05IDcuMjVjLS45NjggMC0xLjc1Ljc4Mi0xLjc1IDEuNzVzLjc4MiAxLjc1IDEuNzUgMS43NSAxLjc1LS43ODIgMS43NS0xLjc1UzkuOTY4IDcuMjUgOSA3LjI1eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-filter {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik00IDEwaDEwVjhINHpNMiA0djJoMTRWNHoiLz48cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik03IDE0aDR2LTJIN3oiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-group {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNCAxNEg3di0yaDlhMiAyIDAgMCAxLTIgMnptLTUtMnYyaDJ2LTJIOXoiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik0xNiA2YTIgMiAwIDAgMC0yLTJINWEyIDIgMCAwIDAtMiAyaDEzek03IDR2Mkg1VjRoMnptOSA2SDdWOGg5djJ6TTkgOHYyaDJWOEg5eiIvPjwvZz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-indeterminate {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSA0LjYyNUE2Ljg5OSA2Ljg5OSAwIDAgMCAyLjU4MyA5IDYuODk5IDYuODk5IDAgMCAwIDkgMTMuMzc1IDYuODk5IDYuODk5IDAgMCAwIDE1LjQxNyA5IDYuODk5IDYuODk5IDAgMCAwIDkgNC42MjV6bTAgNy4yOTJhMi45MTggMi45MTggMCAwIDEgMC01LjgzNCAyLjkxOCAyLjkxOCAwIDAgMSAwIDUuODM0eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-left {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUgOEg2bDQtNC0xLTEtNiA2IDYgNiAxLTEtNC00aDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-loading {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik00IDBoMnYzSDR6Ii8+PHBhdGggaWQ9ImIiIGQ9Ik00IDdoMnYzSDR6Ii8+PHBhdGggaWQ9ImMiIGQ9Ik0wIDRoM3YySDB6Ii8+PHBhdGggaWQ9ImQiIGQ9Ik03IDRoM3YySDd6Ii8+PHBhdGggaWQ9ImUiIGQ9Ik00IDBoMnYzSDR6Ii8+PHBhdGggaWQ9ImYiIGQ9Ik00IDdoMnYzSDR6Ii8+PHBhdGggaWQ9ImciIGQ9Ik0wIDRoM3YySDB6Ii8+PHBhdGggaWQ9ImgiIGQ9Ik03IDRoM3YySDd6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMyA0KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYSIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTQuNS41aDF2MmgtMXoiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMyA0KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYiIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTQuNSA3LjVoMXYyaC0xeiIvPjwvZz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDQpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNjIi8+PHBhdGggc3Ryb2tlPSIjOTc5Nzk3IiBkPSJNLjUgNC41aDJ2MWgtMnoiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMyA0KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjZCIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTcuNSA0LjVoMnYxaC0yeiIvPjwvZz48ZyBvcGFjaXR5PSIuNzE0Ij48ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxLjY3MiAxMC42MjEpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNlIi8+PHBhdGggc3Ryb2tlPSIjOTc5Nzk3IiBkPSJNNC41LjVoMXYyaC0xeiIvPjwvZz48ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxLjY3MiAxMC42MjEpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNmIi8+PHBhdGggc3Ryb2tlPSIjOTc5Nzk3IiBkPSJNNC41IDcuNWgxdjJoLTF6Ii8+PC9nPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1IDEuNjcyIDEwLjYyMSkiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2ciLz48cGF0aCBzdHJva2U9IiM5Nzk3OTciIGQ9Ik0uNSA0LjVoMnYxaC0yeiIvPjwvZz48ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxLjY3MiAxMC42MjEpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNoIi8+PHBhdGggc3Ryb2tlPSIjOTc5Nzk3IiBkPSJNNy41IDQuNWgydjFoLTJ6Ii8+PC9nPjwvZz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-menu {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTIgMTRoMTR2LTJIMnoiLz48cGF0aCBkPSJNMiAxMGgxNFY4SDJ6bTAtNnYyaDE0VjR6Ii8+PC9nPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-minus {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQgMTBINFY4aDEweiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-none {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTIgMTRoNXYtMkgyeiIvPjxwYXRoIGQ9Ik0yIDR2MmgxNFY0em0wIDZoOVY4SDJ6Ii8+PC9nPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-not-allowed {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAxLjVDNC44NiAxLjUgMS41IDQuODYgMS41IDljMCA0LjE0IDMuMzYgNy41IDcuNSA3LjUgNC4xNCAwIDcuNS0zLjM2IDcuNS03LjUgMC00LjE0LTMuMzYtNy41LTcuNS03LjV6TTMgOWMwLTMuMzE1IDIuNjg1LTYgNi02YTUuOTMgNS45MyAwIDAgMSAzLjY3NSAxLjI2OGwtOC40MDggOC40MDdBNS45MjcgNS45MjcgMCAwIDEgMyA5em02IDZhNS45MjcgNS45MjcgMCAwIDEtMy42NzUtMS4yNjhsOC40MDctOC40MDdBNS45MjcgNS45MjcgMCAwIDEgMTUgOWMwIDMuMzE1LTIuNjg1IDYtNiA2eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-paste {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEgM2MwLS41LS41LTEuNS0yLTEuNVM3IDIuNSA3IDNINGExIDEgMCAwIDAtMSAxdjExYTEgMSAwIDAgMCAxIDFoMTBhMSAxIDAgMCAwIDEtMVY0YTEgMSAwIDAgMC0xLTFoLTN6bS0yIC4yNzNjLjM2NyAwIC42NjcuMjg2LjY2Ny42MzYgMCAuMzUtLjMuNjM2LS42NjcuNjM2LS4zNjcgMC0uNjY3LS4yODYtLjY2Ny0uNjM2IDAtLjM1LjMtLjYzNi42NjctLjYzNnpNMTQgMTVINFY0aDJ2Mmg2VjRoMnYxMXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-pin {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiMzMzMiIGQ9Ik05LjY1NyAybDUuNDEgNS40MS0uNTQuNTQyLS41NDItLjU0MS00LjMyOCAyLjE2NCAxLjA4MiAxLjA4Mi0xLjU0MSAxLjU0TDQuODY5IDcuODdsMS41NDItMS41NCAxLjA4MiAxLjA4IDIuMTY0LTQuMzI5LS41NDEtLjU0eiIvPjxwYXRoIGQ9Ik02IDExbC0yLjUgMi41IiBzdHJva2U9IiMzMzMiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-pivot {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIHN0cm9rZT0iIzMzMyIgZD0iTTExLjUgMTAuNUwxMyA5bDEuNSAxLjVtLTUgMUw4IDEzbDEuNSAxLjUiLz48cGF0aCBkPSJNMCAwaDE4djE4SDB6Ii8+PHBhdGggZD0iTTMgMWgxMmEyIDIgMCAwIDEgMiAydjEyYTIgMiAwIDAgMS0yIDJIM2EyIDIgMCAwIDEtMi0yVjNhMiAyIDAgMCAxIDItMnptMyAxNHYtM0gzdjNoM3ptMC00VjhIM3YzaDN6bTAtNVYzSDN2M2gzem01IDBWM0g3djNoNHptNCA5VjhIN3Y3aDh6bTAtOVYzaC0zdjNoM3oiIGZpbGw9IiMzMzMiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-plus {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQgMTBoLTR2NEg4di00SDRWOGg0VjRoMnY0aDR6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-right {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAzTDggNGw0IDRIM3YyaDlsLTQgNCAxIDEgNi02eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-small-left {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTNMNiA5bDQtNHoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-small-right {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCA1bDQgNC00IDR6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-small-up {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSAxMGw0LTQgNCA0eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-small-down {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSA4bDQgNCA0LTR6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-tick {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi41IDEyLjVMMyA5bC0xIDEgNC41IDQuNSA5LTktMS0xeiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-cross {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQgNWwtMS0xLTQgNC00LTQtMSAxIDQgNC00IDQgMSAxIDQtNCA0IDQgMS0xLTQtNHoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-tree-open {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuNSA2LjVMOSAxMCA1LjUgNi41bC0xIDFMOSAxMmw0LjUtNC41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-tree-closed {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiAxMi41TDkuNSA5IDYgNS41bDEtMUwxMS41IDkgNyAxMy41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-icon-tree-indeterminate {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMyA4aDEydjEuNUgzeiIgZmlsbD0iIzMzMyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px;
	  display: inline-block; }
	.ag-theme-material .ag-header-cell-menu-button .ag-icon-menu {
	  display: block;
	  height: 56px; }
	.ag-theme-material .ag-icon-checkbox-checked:empty {
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiNGRjQwODEiLz48L3N2Zz4=); }
	.ag-theme-material .ag-menu {
	  background: #fff;
	  border-radius: 2px;
	  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	  padding: 8px;
	  padding: 0;
	  z-index: 5; }
	  .ag-theme-material .ag-menu .ag-menu-list {
		cursor: default;
		margin-bottom: 8px;
		margin-top: 8px;
		width: 100%; }
	  .ag-theme-material .ag-menu .ag-menu-option {
		line-height: 32px;
		padding-left: 16px;
		padding-right: 16px; }
		.ag-theme-material .ag-menu .ag-menu-option > span {
		  display: table-cell;
		  vertical-align: middle; }
	  .ag-theme-material .ag-menu .ag-menu-option-active {
		background: #eee; }
	  .ag-theme-material .ag-menu .ag-menu-option-disabled {
		opacity: 0.5; }
	  .ag-theme-material .ag-menu .ag-menu-option-icon {
		padding-left: 8px;
		padding-right: 8px; }
		.ag-theme-material .ag-menu .ag-menu-option-icon span {
		  height: 18px;
		  line-height: 0;
		  margin-top: 8px; }
	  .ag-theme-material .ag-menu .ag-menu-option-shortcut {
		padding-left: 16px; }
	  .ag-theme-material .ag-menu .ag-menu-separator {
		margin-left: -8px; }
		.ag-theme-material .ag-menu .ag-menu-separator > span {
		  background-image: url("data:image/svg+xml;utf8,<svg width='1' height='16px' viewBox='0 0 1 16px' xmlns='http://www.w3.org/2000/svg'> <line x1='0' y1='8px' x2='1' y2='8px' stroke-width='1' stroke='#e0e0e0'/> </svg>");
		  height: 16px; }
	  .ag-theme-material .ag-menu .ag-menu-option-popup-pointer {
		width: 34px; }
	.ag-theme-material.ag-dnd-ghost {
	  background: #fff;
	  border-radius: 2px;
	  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	  border: 1px solid #e0e0e0;
	  color: rgba(0, 0, 0, 0.54);
	  font: 700 12px "Roboto", sans-serif;
	  height: 56px !important;
	  line-height: 56px;
	  margin: 0;
	  padding: 0 16px;
	  transform: translateY(16px);
	  z-index: 5; }
	  .ag-theme-material.ag-dnd-ghost span,
	  .ag-theme-material.ag-dnd-ghost div {
		float: left;
		height: 100%;
		margin: 0;
		padding: 0; }
	  .ag-theme-material.ag-dnd-ghost .ag-dnd-ghost-icon {
		margin-right: 8px;
		opacity: 0.87; }
	.ag-theme-material .ag-tab-header {
	  background: #eee;
	  min-width: 220px;
	  width: 100%;
	  display: table; }
	  .ag-theme-material .ag-tab-header .ag-tab {
		border-bottom: 2px solid transparent;
		height: 32px;
		text-align: center;
		vertical-align: middle;
		display: table-cell; }
		.ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-filter {
		  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjM0Y1MUI1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik00IDEwaDEwVjhINHpNMiA0djJoMTRWNHoiLz48cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik03IDE0aDR2LTJIN3oiLz48L2c+PC9zdmc+);
		  display: inline-block; }
		.ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-columns {
		  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMyA0aDN2Mkgzem01IDBoM3YySDh6bTUgMGgzdjJoLTN6TTMgOGgzdjJIM3ptNSAwaDN2Mkg4em01IDBoM3YyaC0zek0zIDEyaDN2Mkgzem01IDBoM3YySDh6bTUgMGgzdjJoLTN6IiBmaWxsPSIjM0Y1MUI1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
		  display: inline-block; }
		.ag-theme-material .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-menu {
		  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjM0Y1MUI1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTIgMTRoMTR2LTJIMnoiLz48cGF0aCBkPSJNMiAxMGgxNFY4SDJ6bTAtNnYyaDE0VjR6Ii8+PC9nPjwvc3ZnPg==);
		  display: inline-block; }
	.ag-theme-material .ag-tab-body {
	  padding: 8px 0; }
	  .ag-theme-material .ag-tab-body .ag-filter-select {
		margin: 8px;
		width: calc(100% - 16px); }
	  .ag-theme-material .ag-tab-body .ag-menu-list {
		margin-bottom: 0;
		margin-top: 0; }
		.ag-theme-material .ag-tab-body .ag-menu-list > div:first-child > span {
		  padding-top: 0; }
		.ag-theme-material .ag-tab-body .ag-menu-list > div:last-child > span {
		  padding-bottom: 0; }
		.ag-theme-material .ag-tab-body .ag-menu-list > div:last-child > .ag-menu-option-popup-pointer {
		  background-position-y: 0; }
	.ag-theme-material .ag-filter-select {
	  margin: 8px;
	  width: calc(100% - 16px); }
	.ag-theme-material .ag-filter input[type="text"],
	.ag-theme-material .ag-filter input[type="date"] {
	  background: transparent;
	  box-sizing: border-box;
	  color: rgba(0, 0, 0, 0.87);
	  font-family: inherit;
	  font-size: inherit;
	  height: 24px;
	  padding-bottom: 8px;
	  border-width: 0;
	  border-bottom: 1px solid #e0e0e0;
	  padding-left: 8px; }
	  .ag-theme-material .ag-filter input[type="text"]:focus,
	  .ag-theme-material .ag-filter input[type="date"]:focus {
		border-bottom: 2px solid #3f51b5;
		outline: none;
		padding-bottom: 7px; }
	  .ag-theme-material .ag-filter input[type="text"]::placeholder,
	  .ag-theme-material .ag-filter input[type="date"]::placeholder {
		color: rgba(0, 0, 0, 0.38); }
	.ag-theme-material .ag-filter label {
	  display: block;
	  padding-left: 8px; }
	.ag-theme-material .ag-filter .ag-set-filter-list {
	  height: 260px;
	  padding-top: 8px; }
	.ag-theme-material .ag-filter .ag-filter-header-container {
	  box-sizing: border-box;
	  height: 40px; }
	.ag-theme-material .ag-filter .ag-filter-header-container:nth-child(2) {
	  border-bottom: 1px solid #e0e0e0; }
	.ag-theme-material .ag-filter .ag-filter-checkbox {
	  float: left;
	  height: 40px;
	  margin-right: 8px;
	  padding-top: 4px; }
	.ag-theme-material .ag-filter .ag-filter-value {
	  height: 40px;
	  line-height: 28px; }
	.ag-theme-material .ag-filter .ag-filter-apply-panel {
	  display: flex;
	  justify-content: flex-end;
	  padding: 8px;
	  padding-top: 16px; }
	  .ag-theme-material .ag-filter .ag-filter-apply-panel button {
		appearance: none;
		background-color: transparent;
		border: 0;
		color: #3f51b5;
		font-family: inherit;
		font-size: inherit;
		margin: 0;
		padding: 0;
		text-transform: uppercase; }
	  .ag-theme-material .ag-filter .ag-filter-apply-panel button + button {
		margin-left: 16px; }
	.ag-theme-material .ag-column-select-panel .ag-column-select-column-group,
	.ag-theme-material .ag-column-select-panel .ag-column-select-column {
	  height: 32px;
	  line-height: 32px;
	  margin-left: 0; }
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column-group span,
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column span {
		float: left;
		height: 100%; }
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column-group .ag-column-select-indent,
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column .ag-column-select-indent {
		width: 16px; }
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column-group .ag-column-select-checkbox,
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column-group .ag-column-group-icons,
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column .ag-column-select-checkbox,
	  .ag-theme-material .ag-column-select-panel .ag-column-select-column .ag-column-group-icons {
		margin-left: 8px;
		margin-right: 8px; }
	.ag-theme-material .ag-column-select-panel .ag-column-container {
	  padding-top: 8px; }
	.ag-theme-material .ag-column-select-panel .ag-column-select-column.ag-toolpanel-add-group-indent {
	  margin-left: 34px; }
	.ag-theme-material .ag-column-tool-panel {
	  display: flex; }
	.ag-theme-material .ag-filter-body {
	  flex-grow: 1;
	  flex-shrink: 1;
	  padding-right: 8px; }
	.ag-theme-material .ag-column-tool-panel-item button {
	  font-family: "Roboto", sans-serif;
	  font-size: 13px;
	  height: 100%; }
	.ag-theme-material .ag-filter-filter {
	  margin-bottom: 8px; }
	.ag-theme-material .ag-column-select-header {
	  border-bottom: 1px solid #e0e0e0;
	  box-sizing: border-box;
	  height: 56px;
	  padding-top: 8px; }
	  .ag-theme-material .ag-column-select-header label {
		display: block;
		padding-left: 8px; }
		.ag-theme-material .ag-column-select-header label .ag-filter-checkbox {
		  float: left;
		  margin-right: 8px; }
	  .ag-theme-material .ag-column-select-header .ag-column-tool-panel a {
		margin: 0 8px;
		padding-top: 4px; }
	.ag-theme-material .ag-group-child-count::before {
	  content: " "; }
	.ag-theme-material .ag-column-panel {
	  border-right: 0; }
	  .ag-theme-material .ag-column-panel .ag-pivot-mode {
		border-bottom: 1px solid #e0e0e0;
		box-sizing: border-box;
		height: 56px;
		line-height: 56px; }
		.ag-theme-material .ag-column-panel .ag-pivot-mode span {
		  float: left;
		  height: 100%; }
		.ag-theme-material .ag-column-panel .ag-pivot-mode .ag-pivot-mode-select {
		  margin-left: 8px; }
		  .ag-theme-material .ag-column-panel .ag-pivot-mode .ag-pivot-mode-select .ag-checkbox-label {
			margin-left: 8px; }
	  .ag-theme-material .ag-column-panel .ag-column-select-panel {
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 7px;
		padding-top: 0; }
	  .ag-theme-material .ag-column-panel .ag-column-drop {
		border-bottom: 1px solid #e0e0e0;
		clear: both;
		overflow: auto;
		padding: 8px 0;
		padding-bottom: 16px; }
		.ag-theme-material .ag-column-panel .ag-column-drop .ag-icon {
		  float: left;
		  height: 40px;
		  margin: 0 8px; }
		.ag-theme-material .ag-column-panel .ag-column-drop .ag-column-drop-title {
		  clear: right;
		  float: left;
		  height: 40px;
		  line-height: 40px;
		  width: calc(100% - 34px); }
		.ag-theme-material .ag-column-panel .ag-column-drop .ag-column-drop-empty-message {
		  clear: both;
		  color: rgba(0, 0, 0, 0.38);
		  font: 700 12px "Roboto", sans-serif;
		  line-height: 16px;
		  padding-left: 32px;
		  padding-right: 8px; }
		.ag-theme-material .ag-column-panel .ag-column-drop:last-child {
		  border-bottom: 0; }
	.ag-theme-material .ag-filter-icon:empty {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik00IDEwaDEwVjhINHpNMiA0djJoMTRWNHoiLz48cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik03IDE0aDR2LTJIN3oiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-sort-ascending-icon:empty {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-sort-descending-icon:empty {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-sort-none-icon:empty {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTIgMTRoNXYtMkgyeiIvPjxwYXRoIGQ9Ik0yIDR2MmgxNFY0em0wIDZoOVY4SDJ6Ii8+PC9nPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-numeric-header .ag-header-cell-label .ag-header-icon {
	  margin-left: 0;
	  margin-right: 8px; }
	.ag-theme-material .ag-paging-panel {
	  align-items: center;
	  border-top: 1px solid #e0e0e0;
	  color: rgba(0, 0, 0, 0.54);
	  display: flex;
	  height: 56px;
	  justify-content: flex-end;
	  padding: 0 24px; }
	  .ag-theme-material .ag-paging-panel > span {
		margin-left: 32px; }
	.ag-theme-material button[ref="btFirst"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik00LjUgNC41SDZ2OUg0LjV6Ii8+PHBhdGggZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTQgMTIuNUwxMC41IDkgMTQgNS41bC0xLTFMOC41IDlsNC41IDQuNXoiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  width: 18px;
	  overflow: hidden;
	  text-indent: 100%;
	  appearance: none;
	  border: 0;
	  opacity: 0.54;
	  padding: 0; }
	  .ag-theme-material button[ref="btFirst"][disabled] {
		opacity: 0.38; }
	.ag-theme-material button[ref="btPrevious"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEuNSAxMi41TDggOWwzLjUtMy41LTEtMUw2IDlsNC41IDQuNXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  width: 18px;
	  overflow: hidden;
	  text-indent: 100%;
	  appearance: none;
	  border: 0;
	  opacity: 0.54;
	  padding: 0; }
	  .ag-theme-material button[ref="btPrevious"][disabled] {
		opacity: 0.38; }
	.ag-theme-material button[ref="btLast"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMy41IDQuNUgxMnY5aDEuNXoiLz48cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik00IDEyLjVMNy41IDkgNCA1LjVsMS0xTDkuNSA5IDUgMTMuNXoiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  width: 18px;
	  overflow: hidden;
	  text-indent: 100%;
	  appearance: none;
	  border: 0;
	  opacity: 0.54;
	  padding: 0; }
	  .ag-theme-material button[ref="btLast"][disabled] {
		opacity: 0.38; }
	.ag-theme-material button[ref="btNext"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiAxMi41TDkuNSA5IDYgNS41bDEtMUwxMS41IDkgNyAxMy41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  width: 18px;
	  overflow: hidden;
	  text-indent: 100%;
	  appearance: none;
	  border: 0;
	  opacity: 0.54;
	  padding: 0; }
	  .ag-theme-material button[ref="btNext"][disabled] {
		opacity: 0.38; }
	.ag-theme-material .ag-rtl button[ref="btFirst"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMy41IDQuNUgxMnY5aDEuNXoiLz48cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik00IDEyLjVMNy41IDkgNCA1LjVsMS0xTDkuNSA5IDUgMTMuNXoiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-rtl button[ref="btPrevious"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiAxMi41TDkuNSA5IDYgNS41bDEtMUwxMS41IDkgNyAxMy41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-rtl button[ref="btLast"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik00LjUgNC41SDZ2OUg0LjV6Ii8+PHBhdGggZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTQgMTIuNUwxMC41IDkgMTQgNS41bC0xLTFMOC41IDlsNC41IDQuNXoiLz48L2c+PC9zdmc+);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-rtl button[ref="btNext"] {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEuNSAxMi41TDggOWwzLjUtMy41LTEtMUw2IDlsNC41IDQuNXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-row-selected {
	  background-color: #f5f5f5; }
	.ag-theme-material .ag-cell-range-selected:not(.ag-cell-focus) {
	  background-color: #e8eaf6; }
	.ag-theme-material .ag-cell-inline-editing {
	  border-radius: 2px;
	  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	  background: #f5f5f5;
	  height: 72px;
	  line-height: normal;
	  padding: 24px;
	  z-index: 2; }
	  .ag-theme-material .ag-cell-inline-editing input[type="text"],
	  .ag-theme-material .ag-cell-inline-editing input[type="tel"],
	  .ag-theme-material .ag-cell-inline-editing input[type="date"],
	  .ag-theme-material .ag-cell-inline-editing input[type="datetime-local"] {
		background: transparent;
		box-sizing: border-box;
		color: rgba(0, 0, 0, 0.87);
		font-family: inherit;
		font-size: inherit;
		height: 24px;
		padding-bottom: 8px;
		border-width: 0;
		border-bottom: 1px solid #e0e0e0; }
		.ag-theme-material .ag-cell-inline-editing input[type="text"]:focus,
		.ag-theme-material .ag-cell-inline-editing input[type="tel"]:focus,
		.ag-theme-material .ag-cell-inline-editing input[type="date"]:focus,
		.ag-theme-material .ag-cell-inline-editing input[type="datetime-local"]:focus {
		  border-bottom: 2px solid #3f51b5;
		  outline: none;
		  padding-bottom: 7px; }
		.ag-theme-material .ag-cell-inline-editing input[type="text"]::placeholder,
		.ag-theme-material .ag-cell-inline-editing input[type="tel"]::placeholder,
		.ag-theme-material .ag-cell-inline-editing input[type="date"]::placeholder,
		.ag-theme-material .ag-cell-inline-editing input[type="datetime-local"]::placeholder {
		  color: rgba(0, 0, 0, 0.38); }
	  .ag-theme-material .ag-cell-inline-editing select {
		height: auto; }
	.ag-theme-material .ag-popup-editor {
	  border-radius: 2px;
	  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	  background: #f5f5f5;
	  padding: 0;
	  z-index: 1; }
	  .ag-theme-material .ag-popup-editor .ag-large-textarea textarea {
		background: transparent;
		box-sizing: border-box;
		color: rgba(0, 0, 0, 0.87);
		font-family: inherit;
		font-size: inherit;
		padding: 24px;
		padding-bottom: 8px;
		border-width: 0;
		border-bottom: 1px solid #e0e0e0;
		height: auto;
		}
		.ag-theme-material .ag-popup-editor .ag-large-textarea textarea:focus {
		  border-bottom: 2px solid #3f51b5;
		  outline: none;
		  padding-bottom: 7px; }
		.ag-theme-material .ag-popup-editor .ag-large-textarea textarea::placeholder {
		  color: rgba(0, 0, 0, 0.38); }
	.ag-theme-material .ag-rich-select {
	  background-color: #f5f5f5;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuNSA2LjVMOSAxMCA1LjUgNi41bC0xIDFMOSAxMmw0LjUtNC41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position-x: calc(100% - 8px);
	  background-position-y: 16px;
	  background-repeat: no-repeat; }
	  .ag-theme-material .ag-rich-select .ag-rich-select-list {
		height: 312px; }
	  .ag-theme-material .ag-rich-select .ag-rich-select-value {
		height: 48px;
		line-height: 48px;
		padding-left: 24px; }
	  .ag-theme-material .ag-rich-select .ag-virtual-list-item {
		cursor: default;
		height: 48px;
		line-height: 48px; }
		.ag-theme-material .ag-rich-select .ag-virtual-list-item:hover {
		  background-color: #eee; }
	  .ag-theme-material .ag-rich-select .ag-rich-select-row {
		padding-left: 24px; }
	  .ag-theme-material .ag-rich-select .ag-rich-select-row-selected {
		background-color: #f5f5f5; }
	.ag-theme-material .ag-floating-filter-body {
	  float: left;
	  height: 100%;
	  margin-right: 0;
	  width: calc(100% - 34px); }
	  .ag-theme-material .ag-floating-filter-body input {
		background: transparent;
		box-sizing: border-box;
		color: rgba(0, 0, 0, 0.87);
		font-family: inherit;
		font-size: inherit;
		height: 24px;
		padding-bottom: 8px;
		border-width: 0;
		border-bottom: 1px solid #e0e0e0; }
		.ag-theme-material .ag-floating-filter-body input:focus {
		  border-bottom: 2px solid #3f51b5;
		  outline: none;
		  padding-bottom: 7px; }
		.ag-theme-material .ag-floating-filter-body input::placeholder {
		  color: rgba(0, 0, 0, 0.38); }
	.ag-theme-material .ag-floating-filter-full-body input {
	  background: transparent;
	  box-sizing: border-box;
	  color: rgba(0, 0, 0, 0.87);
	  font-family: inherit;
	  font-size: inherit;
	  height: 24px;
	  padding-bottom: 8px;
	  border-width: 0;
	  border-bottom: 1px solid #e0e0e0; }
	  .ag-theme-material .ag-floating-filter-full-body input:focus {
		border-bottom: 2px solid #3f51b5;
		outline: none;
		padding-bottom: 7px; }
	  .ag-theme-material .ag-floating-filter-full-body input::placeholder {
		color: rgba(0, 0, 0, 0.38); }
	.ag-theme-material .ag-floating-filter-button {
	  float: right;
	  line-height: 18px;
	  margin-top: 20px; }
	  .ag-theme-material .ag-floating-filter-button button {
		appearance: none;
		background: transparent;
		border: 0;
		height: 18px;
		padding: 0;
		width: 18px; }
	.ag-theme-material .ag-cell-label-container {
	  height: 100%; }
	.ag-theme-material .ag-header-group-cell-label {
	  height: 100%; }
	  .ag-theme-material .ag-header-group-cell-label span {
		float: left;
		height: 100%; }
	.ag-theme-material .ag-header-select-all {
	  height: 100%;
	  margin-right: 24px; }
	  .ag-theme-material .ag-header-select-all span {
		height: 100%; }
	.ag-theme-material .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
	  float: left;
	  width: calc(100% - 18px - 24px); }
	.ag-theme-material .ag-selection-checkbox span,
	.ag-theme-material .ag-group-expanded span,
	.ag-theme-material .ag-group-contracted span {
	  margin-right: 24px; }
	.ag-theme-material .ag-selection-checkbox span {
	  position: relative;
	  top: 4px; }
	.ag-theme-material .ag-group-expanded .ag-icon-contracted:empty {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuNSA2LjVMOSAxMCA1LjUgNi41bC0xIDFMOSAxMmw0LjUtNC41eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-column-drop-horizontal {
	  background-color: #eee;
	  height: 48px;
	  line-height: 32px;
	  padding-left: 24px; }
	  .ag-theme-material .ag-column-drop-horizontal.ag-width-half {
		margin-bottom: -3px; }
	  .ag-theme-material .ag-column-drop-horizontal span {
		float: left;
		height: 100%; }
	  .ag-theme-material .ag-column-drop-horizontal > div:first-child {
		float: left;
		height: 100%; }
	  .ag-theme-material .ag-column-drop-horizontal .ag-icon-group,
	  .ag-theme-material .ag-column-drop-horizontal .ag-icon-pivot {
		margin-right: 24px; }
	  .ag-theme-material .ag-column-drop-horizontal .ag-right-arrow {
		background-color: transparent;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAzTDggNGw0IDRIM3YyaDlsLTQgNCAxIDEgNi02eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 18px 18px;
		height: 18px;
		opacity: 0.87;
		width: 18px; }
	  .ag-theme-material .ag-column-drop-horizontal .ag-left-arrow {
		background-color: transparent;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUgOEg2bDQtNC0xLTEtNiA2IDYgNiAxLTEtNC00aDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 18px 18px;
		height: 18px;
		opacity: 0.87;
		width: 18px; }
	  .ag-theme-material .ag-column-drop-horizontal .ag-left-arrow,
	  .ag-theme-material .ag-column-drop-horizontal .ag-right-arrow {
		overflow: hidden;
		text-indent: 100%;
		height: 100%;
		margin: 0 8px;
		opacity: 0.54; }
	  .ag-theme-material .ag-column-drop-horizontal .ag-column-drop-empty-message {
		height: 100%;
		line-height: 48px;
		opacity: 0.38; }
	.ag-theme-material .ag-column-drop-cell {
	  background: #e0e0e0;
	  border-radius: 32px;
	  box-sizing: border-box;
	  height: 32px !important;
	  margin-top: 8px;
	  padding: 0 4px; }
	  .ag-theme-material .ag-column-drop-cell .ag-column-drop-cell-text {
		height: 100%;
		line-height: 32px;
		margin: 0 8px; }
	  .ag-theme-material .ag-column-drop-cell .ag-column-drop-cell-button {
		background-color: transparent;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAxLjVBNy40OTMgNy40OTMgMCAwIDAgMS41IDljMCA0LjE0OCAzLjM1MyA3LjUgNy41IDcuNSA0LjE0OCAwIDcuNS0zLjM1MiA3LjUtNy41IDAtNC4xNDctMy4zNTItNy41LTcuNS03LjV6bTMuNzUgMTAuMTkzbC0xLjA1NyAxLjA1N0w5IDEwLjA1NyA2LjMwOCAxMi43NSA1LjI1IDExLjY5MyA3Ljk0MiA5IDUuMjUgNi4zMDggNi4zMDggNS4yNSA5IDcuOTQybDIuNjkzLTIuNjkyIDEuMDU3IDEuMDU4TDEwLjA1NyA5bDIuNjkzIDIuNjkzeiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 18px 18px;		
		width: 18px;
		overflow: hidden;
		text-indent: 100%;
		min-width: 32px;
		height: 100%;
		margin: 0 4px;
		opacity: 0.54; }
		.ag-theme-material .ag-column-drop-cell .ag-column-drop-cell-button:hover {
		  opacity: 0.87; }
	  .ag-theme-material .ag-column-drop-cell .ag-column-drag {
		margin-left: 16px;
		margin-top: 4px;
		width: 18px; }
	.ag-theme-material .ag-select-agg-func-popup {
	  border-radius: 2px;
	  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	  background: #fff;
	  height: 140px;
	  padding: 0; }
	  .ag-theme-material .ag-select-agg-func-popup .ag-virtual-list-item {
		cursor: default;
		line-height: 40px;
		padding-left: 16px; }
	.ag-theme-material .ag-set-filter-list,
	.ag-theme-material .ag-menu-column-select-wrapper {
	  width: auto; }
	.ag-theme-material .ag-column-drop-vertical > .ag-column-drop-cell {
	  float: left;
	  margin-bottom: 8px;
	  margin-left: 8px;
	  margin-top: 0; }
	.ag-theme-material .ag-cell-data-changed {
	  background-color: #00acc1 !important; }
	.ag-theme-material .ag-cell-data-changed-animation {
	  background-color: transparent;
	  transition: background-color 1s; }
	.ag-theme-material .ag-stub-cell {
	  padding-left: 24px;
	  padding-top: 8px; }
	  .ag-theme-material .ag-stub-cell .ag-loading-icon {
		float: left;
		height: 100%; }
	  .ag-theme-material .ag-stub-cell .ag-loading-text {
		float: left;
		height: 100%;
		margin-left: 8px;
		margin-top: 8px; }
	.ag-theme-material .ag-rtl .ag-numeric-cell {
	  text-align: left; }
	.ag-theme-material .ag-rtl .ag-header-cell-menu-button {
	  float: left; }
	.ag-theme-material .ag-rtl .ag-header-cell-label {
	  float: right;
	  width: calc(100% - 18px); }
	  .ag-theme-material .ag-rtl .ag-header-cell-label > span {
		float: right; }
	  .ag-theme-material .ag-rtl .ag-header-cell-label .ag-header-icon {
		margin-top: 2px; }
	.ag-theme-material .ag-rtl .ag-numeric-header .ag-header-cell-menu-button {
	  float: right; }
	.ag-theme-material .ag-rtl .ag-numeric-header .ag-header-cell-label {
	  float: left; }
	  .ag-theme-material .ag-rtl .ag-numeric-header .ag-header-cell-label > span {
		float: left; }
	.ag-theme-material .ag-rtl .ag-column-panel .ag-pivot-mode span {
	  float: right; }
	.ag-theme-material .ag-rtl .ag-column-panel .ag-pivot-mode .ag-pivot-mode-select {
	  margin-right: 8px; }
	  .ag-theme-material .ag-rtl .ag-column-panel .ag-pivot-mode .ag-pivot-mode-select .ag-checkbox-label {
		margin-right: 8px; }
	.ag-theme-material .ag-rtl .ag-column-panel .ag-column-drop .ag-icon {
	  float: right; }
	.ag-theme-material .ag-rtl .ag-column-panel .ag-column-drop .ag-column-drop-title {
	  clear: left;
	  float: right; }
	.ag-theme-material .ag-rtl .ag-column-panel .ag-column-drop .ag-column-drop-empty-message {
	  padding-left: 8px;
	  padding-right: 32px; }
	.ag-theme-material .ag-rtl .ag-filter-checkbox {
	  float: right;
	  margin-left: 8px; }
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column-group span,
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column span {
	  float: right; }
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column-group .ag-column-select-checkbox,
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column-group .ag-column-group-icons,
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column .ag-column-select-checkbox,
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column .ag-column-group-icons {
	  margin-left: 8px;
	  margin-right: 8px; }
	.ag-theme-material .ag-rtl .ag-column-select-panel .ag-column-select-column.ag-toolpanel-add-group-indent {
	  margin-left: 0;
	  margin-right: 34px; }
	.ag-theme-material .ag-rtl .ag-icon-tree-closed {
	  background-color: transparent;
	  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEuNSAxMi41TDggOWwzLjUtMy41LTEtMUw2IDlsNC41IDQuNXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: 18px 18px;
	  height: 18px;
	  opacity: 0.87;
	  width: 18px; }
	.ag-theme-material .ag-rtl .ag-header-group-cell-label {
	  height: 100%; }
	  .ag-theme-material .ag-rtl .ag-header-group-cell-label span {
		float: right;
		height: 100%; }
	.ag-theme-material .ag-rtl .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
	  float: right; }
	.ag-theme-material .ag-rtl .ag-header-select-all {
	  margin-left: 24px;
	  margin-right: 0; }
	.ag-theme-material .ag-rtl .ag-selection-checkbox span,
	.ag-theme-material .ag-rtl .ag-group-expanded span,
	.ag-theme-material .ag-rtl .ag-group-contracted span {
	  margin-left: 24px;
	  margin-right: 0; }
	.ag-theme-material .ag-rtl .ag-column-drop-horizontal {
	  padding-right: 24px; }
	  .ag-theme-material .ag-rtl .ag-column-drop-horizontal span {
		float: right; }
	  .ag-theme-material .ag-rtl .ag-column-drop-horizontal > div:first-child {
		float: right; }
	  .ag-theme-material .ag-rtl .ag-column-drop-horizontal .ag-icon-group,
	  .ag-theme-material .ag-rtl .ag-column-drop-horizontal .ag-icon-pivot {
		margin-left: 24px;
		margin-right: 0; }
	  .ag-theme-material .ag-rtl .ag-column-drop-horizontal .ag-right-arrow {
		background-color: transparent;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAzTDggNGw0IDRIM3YyaDlsLTQgNCAxIDEgNi02eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 18px 18px;
		opacity: 0.87;
		width: 18px;
		height: 100%; }
	  .ag-theme-material .ag-rtl .ag-column-drop-horizontal .ag-left-arrow {
		background-color: transparent;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUgOEg2bDQtNC0xLTEtNiA2IDYgNiAxLTEtNC00aDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 18px 18px;
		opacity: 0.87;
		width: 18px;
		height: 100%; }
	.ag-theme-material .ag-rtl .ag-floating-filter-body {
	  float: right;
	  margin-left: 0; }
	.ag-theme-material .ag-rtl .ag-floating-filter-button {
	  float: left; }
	.ag-theme-material .ag-rtl .ag-header .ag-header-cell-resize {
	  left: -8px;
	  right: auto; }
	.ag-theme-material .ag-rtl .ag-header .ag-header-cell-resize::after {
	  border-left: 1px solid #e0e0e0;
	  border-right: 0; }
	.ag-theme-material .ag-rtl .ag-column-select-header .ag-filter-body {
	  margin-left: 8px;
	  margin-right: 0; }
	.ag-theme-material .ag-rtl .ag-column-drag {
	  background-position-x: right; }
	.ag-theme-material .ag-status-bar {
	  background: #fff;
	  border: 1px solid #e0e0e0;
	  border-top: 0;
	  color: rgba(0, 0, 0, 0.38);
	  display: flex;
	  font: 700 12px "Roboto", sans-serif;
	  justify-content: flex-end;
	  padding: 16px 32px; }
	  .ag-theme-material .ag-status-bar .ag-status-bar-item {
		margin-right: 16px; }
		.ag-theme-material .ag-status-bar .ag-status-bar-item span:nth-child(1)::after {
		  content: ":"; }
		.ag-theme-material .ag-status-bar .ag-status-bar-item span:nth-child(2) {
		  color: rgba(0, 0, 0, 0.87); }
	.ag-theme-material .ag-details-row {
	  box-sizing: border-box;
	  padding: 40px; }
	.ag-theme-material .ag-overlay-loading-wrapper {
	  background-color: rgba(255, 255, 255, 0.5); }
	.ag-theme-material .ag-overlay-loading-center {
	  background: #fff;
	  border-radius: 2px;
	  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	  padding: 8px; }
	.ag-theme-material .ag-tool-panel {
	  background-color: #fafafa;
	  border-right: 1px solid #e0e0e0;
	  border-top: 1px solid #e0e0e0; }
	  .ag-theme-material .ag-tool-panel .ag-side-buttons {
		border-bottom: 1px solid #e0e0e0; }
		.ag-theme-material .ag-tool-panel .ag-side-buttons button {
		  background: transparent;
		  border: 0;
		  border-right: 1px solid #e0e0e0;
		  color: rgba(0, 0, 0, 0.87);
		  height: 20px; }
	  .ag-theme-material .ag-tool-panel .ag-panel-container {
		border-right: 1px solid #e0e0e0;
		box-sizing: border-box; }
	  .ag-theme-material .ag-tool-panel.full-width .ag-panel-container {
		border-right: 0; }
	  .ag-theme-material .ag-tool-panel .ag-column-drop {
		min-height: 50px; }
	.ag-theme-material .ag-rtl .ag-tool-panel .ag-panel-container {
	  border-left: 1px solid #e0e0e0;
	  border-right: 0; }
	.ag-theme-material .ag-rtl .ag-tool-panel.full-width .ag-panel-container {
	  border-left: 0; }
	.ag-theme-material .ag-rtl .ag-tool-panel .ag-side-buttons button {
	  border-left: 1px solid #e0e0e0;
	  border-right: 0; }
	.ag-theme-material .ag-column-name-filter {
	  background: transparent;
	  color: rgba(0, 0, 0, 0.87);
	  font-family: inherit;
	  font-size: inherit;
	  height: 24px;
	  padding-bottom: 8px;
	  border-width: 0;
	  border-bottom: 1px solid #e0e0e0;
	  box-sizing: border-box;
	  width: 100%; }
	  .ag-theme-material .ag-column-name-filter:focus {
		border-bottom: 2px solid #3f51b5;
		outline: none;
		padding-bottom: 7px; }
	  .ag-theme-material .ag-column-name-filter::placeholder {
		color: rgba(0, 0, 0, 0.38); }
	.ag-theme-material .sass-variables::after {
	  content: '{ "autoSizePadding": "24px", "headerHeight": "56px", "groupPaddingSize": "42px", "footerPaddingAddition": "32px", "virtualItemHeight": "40px", "aggFuncPopupHeight": "140px", "checkboxIndentWidth": "26px", "leafNodePadding": "24px", "rowHeight": "48px", "gridSize": "8px", "iconSize": "18px" }';
	  display: none; }
	.ag-theme-material .ag-cell-highlight {
	  background-color: #fce4ec !important; }
	.ag-theme-material .ag-cell-highlight-animation {
	  -webkit-transition: background-color 1s;
	  transition: background-color 1s; }
	.ag-theme-material .ag-row-drag {
	  background-position-y: center; }
	.ag-theme-material .ag-column-drag {
	  background-position-y: center; }
	.ag-theme-material .ag-header-cell-resize {
	  right: -7px; }
	.ag-theme-material .ag-cell-range-selected-1:not(.ag-cell-focus) {
	  background-color: #e8eaf6; }
	.ag-theme-material .ag-cell-range-selected-2:not(.ag-cell-focus) {
	  background-color: #d9ddf0; }
	.ag-theme-material .ag-cell-range-selected-3:not(.ag-cell-focus) {
	  background-color: #cbcfeb; }
	.ag-theme-material .ag-cell-range-selected-4:not(.ag-cell-focus) {
	  background-color: #bcc2e5; }
	.ag-theme-material .ag-tool-panel {
	  border-bottom: 0;
	  border-right: 0;
	  border-top: 0; }
	  .ag-theme-material .ag-tool-panel .ag-side-buttons button {
		border: 0;
		color: rgba(0, 0, 0, 0.54);
		font-family: "Roboto", sans-serif;
		font-size: 12px;
		font-weight: 700; }
  
html, body {
	height: 100%;
}
* {
	padding: 0;
	margin: 0;
}
li {
	list-style: none;
}
a:hover {
	text-decoration: none;
}
.btn, .btn:focus {
	outline: 0 !important;
}
.pagecontainer {
	height: 100%;
	position: relative;
}
.pagecontainer.home {
	padding: 24px 34px 0 34px;
}
.home {
	background: #ffffff url("../images/home-Bg.png") no-repeat right top;
}

/* .home
{
background: #ffffff url(../images/home-bg.png) no-repeat fixed right top;
-moz-background-size: 67.7% auto;
-webkit-background-size:67.7% auto;
-o-background-size: 67.7% auto;
background-size: 67.7% auto; 
} */

.home .box-in {
	text-align: center;
}
.btns-assesment a.btn {
	padding-top: 8px;
}
/* Header */
header {
}
.pmm-logo {
	float: left;
	margin: 0;
}
.hd-menu {
	float: right;
	margin-bottom: 0;
}
.hd-menu > li {
	float: right;
	height: 49px;
}
.hd-menu > li a {
	margin: 1px 15px;
	float: left;
}
.user {
	margin: 0;
	border-left: solid 1px #6a9ddc;
}
.user > a {
	padding: 4px 0 0 0;
}
.user > a img {
	margin: 0 5px 0 0;
	float: left;
}
.settings a img {
	margin: 11px 0 0 0;
}
.user > a span {
	font-size: 14px;
	color: #fff;
	float: left;
	margin: 0 10px 0 0;
}
.user > a span b {
	font-size: 12px;
	font-weight: normal;
	clear: both;
	color: #a3b8e3;
	display: block;
}
.user > a img:last-child {
	margin: 9px 5px 0 12px;
	float: left;
}
.dropdown-menu li {
	float: left;
	clear: both;
	width: 100%;
}
.dropdown-menu li a {
	width: 80%;
	border-bottom: solid 1px #ccc;
	padding-left: 10px;
}
.dropdown-menu li:last-child a {
	border: none;
}
.innerpages header {
	background: #328cfd;
	/* height: 60px; */
	height: 70px;
	padding: 0 32px 0 32px;
}
.innerpages .pmm-logo {
	margin-top: 16px;
}
.innerpages .hd-menu {
	margin-top: 6px;
}
.innerpages .user {
	border-left: solid 1px #83b0e8;
}
.innerpages .user a span b {
	color: #92c2fe;
}
/* Header Ends */


/* Body */
.content {
	/* min-height: 300px; */
	min-height: 655px;
	clear: both;
	position: relative;
	float: left;
	width: 100%;
	padding-bottom: 50px;
}
.content h1 {
	font-size: 48px;
	width: 385px;
	text-transform: uppercase;
	margin: 25px 0 0 0;
	float: left;
}
.content h5 {
	clear: both;
	font-size: 16px;
	width: 501px;
	line-height: 22px;
}
.btns-assesment {
	text-align: center;
	margin-top: 80px;
}
.btns-assesment .btn {
	height: 69px;
	width: 363px;
	padding-right: 2px;
	background-image: url("../images/icon-button-arrow.png");
	background-repeat: no-repeat;
	background-position: 98% 29px;
	border-color: transparent;
	box-shadow: 0 0 5px #cccc;
}
.btns-assesment .btn.btn-primary:hover {
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #204d74;
	background-position: 93% 29px;
}
.btns-assesment .btn.btn-primaryja:hover {
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #204d74;
	background-position: 93% 29px;
}
.btns-assesment .btn.btn-primary {
	z-index: 2;
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #187eff;
}
.btns-assesment .btn.btn-primaryja {
	z-index: 2;
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #187eff;
}
.btns-assesment .btn.btn-danger:hover {
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #c9302c;
	background-position: 93% 29px;
}
.btns-assesment .btn.btn-dangerja:hover {
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #c9302c;
	background-position: 93% 29px;
}
.btns-assesment .btn.btn-danger {
	z-index: 2;
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #f7701e;
}
.btns-assesment .btn.btn-dangerja {
	z-index: 2;
	-webkit-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-moz-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	background-color: #f7701e;
}
.btns-assesment .btn-primary {
	background-color: #187eff;
	margin-right: 15px;
}
.btns-assesment .btn-primaryja {
	color: #fff;
	background-color: #187eff;
	margin-right: 15px;
	height: 77px;
}
.btns-assesment .btn-danger {
	background-color: #f7701e;
}
.btns-assesment .btn-dangerja {
	color: #fff;
	background-color: #f7701e;
	height: 77px;
}
.btns-assesment .btn b {
	text-transform: uppercase;
	font-size: 14px;
	float: left;
}
.btns-assesment .btn span {
	width: 238px;
	clear: both;
	font-size: 11px;
	white-space: normal;
	text-align: left;
	float: left;
}
.btns-assesment .btn > span {
	float: left;
	/* width: 238px; */
	width: 270px;
	clear: inherit;
}
.btns-assesment .btn img {
	float: left;
	margin: 7px 18px 0 8px;
}
.panelset {
	margin: 40px 0 0 0;
}
.panelset .box {
	width: 24%;
	min-height: 242px;
	margin-right: 15px;
	display: inline-block;
}
.panelset .box:last-child {
	margin-right: 0;
}
.panelset h3 {
	font-size: 16px;
	text-transform: uppercase;
}
.panelset p {
	font-size: 12px;
	color: #999;
}
.tile1 h3 {
	color: #cd7825;
}
.tile2 h3 {
	color: #1f998a;
}
.tile3 h3 {
	color: #c95cd0;
}
.tile4 h3 {
	color: #5d9d4c;
}
.panelset img {
	margin: 15px 0 10px 0;
}
/*All Inner Pages */

/*View Assesment*/

.innerpages .content {
	background: #eef3f6;
}
.innerpage-hd {
	height: 50px;
	background: #014aa7;
}
.innerpage-hd h2 {
	margin: 17px 10px 0 30px;
	float: left;
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
}
.innerpage-hd h2 img {
	margin: -6px 10px 0 0;
	opacity: 0.6;
	height: 26px;
}
.btn-back {
	background: #3074ca;
	border-radius: 20px;
	float: left;
	/* margin: 9px 10px 0 34px; */
	margin: 25px 10px 0 34px;
	border-color: transparent;
	font-size: 13px;
}
.bodydata {
	padding: 10px;
	float: left;
	width: 100%;
}
/* .bodydata > div {
} */
.bodydata-ls {
	float: left;
	max-width: 295px;
	width: 23%;
	margin: 0 22px 0 0;
}
.bodydata-rs {
	float: right;
	width: 75%;
}
.bodydata-full {
	float: left;
	width: 100%;
}
.box {
	box-shadow: 0 0 5px #ccc;
	background: #fff;
	min-height: 65px;
	float: left;
	width: 100%;
	border-radius: 5px;
	margin-bottom: 5px;
}
.box-in {
	padding: 15px;
	float: left;
	width: 100%;
}
.bodydata h3 {
	margin: 0 0 25px 0;
	font-size: 14px;
	text-transform: uppercase;
}
.form-group label {
	font-size: 12px;
}
.bodydata-ls select.form-control {
	border-radius: 22px;
}
select.form-control {
	font-size: 12px;
}
.bodydata-ls .btn-danger {
	width: 105px;
	border-color: transparent;
	background: linear-gradient(#ff8d59, #ff9d56);
	border-radius: 22px;
	font-size: 14px;
	text-transform: uppercase;
}
h4 {
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0;
	clear: both;
	min-height: 34px;
}
.badge {
	background: #328cfd;
	font-size: 14px;
	margin: 0 0 0 35px;
}
.asmt-block-sm {
	min-height: 250px;
	width: 31.5%;
	float: left;
	background: #fff;
	margin-right: 20px;
	box-shadow: 0 0 5px #ccc;
	border-radius: 5px;
}
.asmt-block-sm:nth-child(3n) {
	margin-right: 0;
}
.bodydata .row {
	margin: 0;
}
.block-sm-row {
	border-bottom: solid 1px #ccc;
}
.small-panel {
	margin-bottom: 20px;
	max-height: 247px;
}
.small-panel:nth-child(3n) {
	margin-right: 0;
}
.small-panel {
	min-height: 224px;
	width: 31.5%;
	float: left;
	margin-right: 25px;
	background: #fff url(../images/icon-note.png) no-repeat 14px 10px;
	border-radius: 5px;
	box-shadow: 0 0 5px #ddd;
}
.panel-inrow {
	border-bottom: solid 1px #d6dddf;
	padding: 6px 15px 6px 34px;
	float: left;
	width: 100%;
}
.panel-inrow p {
	margin: 0;
	font-size: 12px;
	font-family: "OpenSans-Semibold", sans-serif;
	line-height: 17px;
}
.panel-inrow p b {
	font-family: "OpenSans-Bold", sans-serif;
	margin-left: 5px;
}
.small-panel .btn-group-single {
	width: 100%;
}
.small-panel .btn-group-single button {
	width: 100%;
	outline: 0;
	text-transform: uppercase;
	font-size: 12px;
	font-family: "OpenSans-Bold", sans-serif;
	background: #f9fafc;
	border: none;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.statustxt-orng {
	color: #d0851a;
	text-transform: uppercase;
}
.statustxt-blue {
	color: #2d86cb;
	text-transform: uppercase;
}
.statustxt-green {
	color: #58ac21;
	text-transform: uppercase;
}
.ls {
	float: left;
	width: 46%;
	padding-top: 6px;
	padding-bottom: 6px;
	border-right: solid 1px #ccc;
	margin-right: 10px;
	font-size: 12px;
	height: 50px;
	font-family: "OpenSans-Semibold", sans-serif;
}
.rs {
	float: left;
	width: 48%;
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 12px;
	height: 50px;
	font-family: "OpenSans-Semibold", sans-serif;
}
.ls p {
	color: #d01a1a;
	font-size: 20px;
}
.rs p {
	color: #038d1d;
	font-size: 20px;
}
.panel-inrow.createdby {
	padding: 0 20px 0 34px;
}
.sts-org {
	border-top: solid 5px #eba950;
}
.sts-green {
	border-top: solid 5px #80bc59;
}
.sts-blue {
	border-top: solid 5px #60a7ff;
}
.AsmtData .table th, .AsmtData .table td {
	border-color: transparent;
}
.AsmtData .table th {
	font-family: "OpenSans-Semibold", sans-serif;
	font-size: 12px;
	vertical-align: middle;
}
.AsmtData .table td {
	font-family: "OpenSans-Bold", sans-serif;
	font-size: 14px;
	vertical-align: middle;
	padding: 4px 8px;
}
.datarow {
	background: #fff;
	box-shadow: 0 0 4px #ccc;
}
.datarow td:first-child {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.datarow td:last-child {
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.rowspace td {
	height: 9px !important;
	padding: 0 !important;
}
.datarow td {
	border-right: solid 1px #e2e2e2 !important;
}
/*.datarow td:last-child {border-right:solid 1px transparent !important; background: #f7f7f7; text-align: center;} */

/* BOI */

.topHdcols {
	margin: 0;
}
.datarow-plain td {
	border-right: solid 1px #e2e2e2 !important;
	height: 65px;
	color: #505a60;
	font-size: 14px;
	font-family: "OpenSans-Semibold", sans-serif;
	padding: 10px 10px 10px 30px !important;
	vertical-align: middle;
}
.datarow-plain td p {
	margin: 0;
	font-size: 14px;
	font-family: "OpenSans-Bold", sans-serif;
}
.btn-group-boi {
	margin-left: 15px;
}
.data-boi .table {
	width: 66%;
}
.data-boi .table td {
	height: 43px;
}
.AsmtData.data-boi .table td {
	font-size: 12px;
}
.AsmtData.data-boi .table td input[type=text] {
	height: 28px;
	width: 100%;
	padding: 0 6px;
	border-radius: 3px;
	border: solid 1px #91999c;
}
.data-boi .table td:first-child {
	width: 65px;
}
.data-boi .table td.lg-col {
	width: 231px;
}
.data-boi .table td.bois {
	padding-left: 25px;
}
.data-boi .table td.asls {
	background: #f7f7f7;
	color: #d0851a;
}
.data-boi .table td.tobe {
	background: #f7f7f7;
	color: #159300;
}
/* Leading Indicator */
.btn-group-rs {
	float: right;
}
.btn-group .btn {
	text-transform: uppercase;
	font-size: 14px;
	color: #666666;
	padding: 6px 10px;
}
.data-LeadingInd .table td {
	height: 43px;
}
.AsmtData.data-LeadingInd .table td.asls {
	background: #f7f7f7;
	color: #d0851a;
	width: 13.5%;
}
.AsmtData.data-LeadingInd .table td.tobe {
	background: #f7f7f7;
	color: #159300;
	width: 13.5%;
}
.AsmtData.data-LeadingInd .table td input[type=text] {
	height: 27px;
	width: 100%;
	padding: 0 6px;
	border-radius: 3px;
	border: solid 1px #91999c;
}
.AsmtData.data-LeadingInd .table td {
	font-size: 12px;
}
.AsmtData.data-LeadingInd .table td.dname {
	width: 29%;
}
.AsmtData.data-LeadingInd .table td.dname input {
	width: 80%;
}
.AsmtData.data-LeadingInd .table td:first-child {
	width: 19.5%;
}
/*New Assestment*/

.pagecounter {
	float: right;
	/* margin: 25px 64px 0 0; */
	margin: 40px 64px 0 0;
}
.pagecounter li {
	height: 3px;
	background: #3a87e6;
	width: 100px;
	float: left;
	position: relative;
}
.pagecounter li:last-child {
	width: 17px;
}
.pagecounter li span {
	border-radius: 50%;
	background: #328cfd;
	font-size: 18px;
	color: #fff;
	text-align: center;
	width: 34px;
	height: 34px;
	position: absolute;
	left: 0;
	top: -16px;
	padding-top: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.popover-title {
	display: none;
}
.popover-content {
	padding: 6px 12px!important;
	text-transform: uppercase;
	font-size: 12px;
	white-space: nowrap;
}
.pagecounter li span a {
	background: #4ec700 url(../images/icon-tick-white.png) no-repeat center center;
	font-size: 0;
	width: 34px;
	height: 34px;
	display: block;
	border-radius: 50%;
	margin-top: -5px;
	padding-top: 2px;
	color: #fff;
	border: solid 3px #c9d9ec;
}
.bodydata-full .control-label {
	font-size: 13px;
	text-align: left;
}
.btn-group-md .btn {
	min-width: 105px;
	box-shadow: 0 0 4px #ccc;
	border-radius: 22px;
	font-size: 14px;
	text-transform: uppercase;
	border-color: transparent;
	padding: 9px 19px 7px 19px;
	margin-right: 10px;
}
.btn-group-md {
	margin: 5px 0 15px 0;
}
.btn-group-md .btn-danger {
	background: #f7701e !important;
}
.btn-group-md .btn-success {
	background: #80bc59;
}
.bodydata-full .form-control {
	font-size: 12px;
	border-color: #91999c;
}
.bodydata-full .box-in {
	/* padding-top: 25px; */
	padding-top: 15px;
}
.wizdplay {
	margin: -5px 0 0 3px;
}
.pagecounter li span.current {
	background: #e69d3a;
	line-height: 15px;
}
/* PMM ASSESTMENT */
.hdtxt {
	margin: 12px 0 0 0;
	float: left;
}
.btn-group-pmmasmt {
	float: right;
	margin-left: 10px;
}
.btn-group-pmmasmt .btn.icon2type img {
	height: 30px;
}
.btn-group-pmmasmt .btn {
	height: 45px;
	min-width: 50px;
	padding-left: 12px;
	padding-right: 12px;
	border-color: transparent;
	box-shadow: 0 0 4px #ccc;
}
.btn-group-pmmasmt .btn:hover, .downloadComparative .btn:hover, .downloadComparative .btn:focus, .downloadComparative .btn:active, .btn-group-pmmasmt .btn:focus, .btn-group-pmmasmt .btn.active {
	background: #f7701e;
}
.btn-downld:hover img, .btn-downld:focus img, .btn-downld.active img {
	content: url("../images/icon-download-active.png");
}
.btn-print:hover img, .btn-print:focus img, .btn-print.active img {
	content: url("../images/icon-printer-active.png");
}
.btn-graph:hover img, .btn-graph:focus img, .btn-graph.active img {
	content: url("../images/icon-graph-active.png");
}
.btn-list:hover img, .btn-list:focus img, .btn-list.active img {
	content: url("../images/icon-list.png");
}
.btn-graphlist:hover img, .btn-graphlist:focus img, .btn-graphlist.active img {
	content: url("../images/icon-graphlist-active.png");
}
.infoblock {
	float: right;
	background: #fff;
	box-shadow: 0 0 4px #ccc;
	min-width: 100px;
	height: 45px;
	border-radius: 4px;
	padding: 0 12px;
	margin-left: 10px;
}
.infoblock p {
	margin: 0;
	float: left;
	height: 45px;
	padding: 15px 10px 14px 0;
	border-right: solid 1px #ddd;
	font-size: 12px;
	color: #505a60;
}
.infoblock label {
	font-size: 16px;
	text-transform: none;
}
.infoblock span {
	font-size: 24px;
	padding: 9px 0 8px 10px;
	float: left;
}
.infoblock select {
	font-size: 14px;
	width: 202px;
	height: 31px;
	margin: 8px 0 0 10px;
	border-radius: 4px;
}
.greentext {
	color: #038d1d;
}
.redtext {
	color: #d01a1a;
}
.mrgn-rs-30 {
	margin-right: 25px;
}
.pmm-asmt-tbl {
	clear: both;
	margin-top: 20px;
}
.pmm-asmt-tbl .box {
	padding: 0 0 5px 0;
}
.pmm-asmt-tbl .table th {
	vertical-align: middle;
	font-size: 16px;
}
.pmm-asmt-tbl table th:nth-child(9), .pmm-asmt-tbl table th:nth-child(10), .pmm-asmt-tbl table th:nth-child(11), .pmm-asmt-tbl table th:nth-child(12) {
	min-width: 114px;
}
.pmm-asmt-tbl .table td {
	font-size: 14px;
}
.pmm-asmt-tbl .table th, .pmm-asmt-tbl .table td {
	border-right: solid 1px #e2e2e2 !important;
}
.pmm-asmt-tbl table tbody tr:nth-child(even) {
	background: #f7f7f7;
}
.pmm-asmt-tbl table tbody tr:nth-child(odd) {
	background: #FFF;
}
.greentextB {
	color: #038d1d;
}
.redtextB {
	color: #d01a1a;
}
.pmm-asmt-tbl table td .greentextB, .pmm-asmt-tbl table td .redtextB {
	font-size: 18px;
}
/* STORYBOARD-GAP ASSESSMENT */
.graphs {
	margin-top: 15px;
	clear: both;
	float: left;
	width: 100%;
}
.graphsgroup1 {
	margin-top: 15px;
	clear: both;
	float: left;
	width: 100%;
}
.graphsgroup1 > div > div {
	border-right: solid 1px #ddd;
}
.graphsgroup1 > .box img {
	width: 100%;
}
.graphsgroup1 .box > div:last-child {
	border-color: transparent;
}
.graphs .box {
	padding: 15px;
}
.graphs h3 {
	color: #d01a1a;
}
.graphs h3 span {
	color: #666;
	font-size: 12px;
}
/* ADMIN Pages*/
.adminMenu {
	margin: 0 0 0 34px;
	padding-top: 15px;
}
.adminMenu li {
	float: left;
	margin-right: 1px;
}
.adminMenu li a {
	font-size: 12px;
	height: 75px;
	float: left;
	color: #eef3f6;
	padding: 46px 25px 10px 25px;
	text-transform: uppercase;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.adminMenu li a.menu1 {
	background: #014aa7 url(../images/PMM-UserMgt-icon.png) no-repeat center 15px;
}
.adminMenu li a.menu1:hover, .adminMenu li a.menu1:focus, li.active a.menu1 {
	background: #eef3f6 url(../images/PMM-UserMgt-iconHover.png) no-repeat center 15px;
	color: #003982;
}
.adminMenu li a.menu2 {
	background: #014aa7 url(../images/PMM-configuration-icon.png) no-repeat center 15px;
}
.adminMenu li a.menu2:hover, li.active a.menu2, .adminMenu li a.menu2:focus {
	background: #eef3f6 url(../images/PMM-configuration-iconHover.png) no-repeat center 15px;
	color: #003982;
}
.adminMenu li a.menu3 {
	background: #014aa7 url(../images/PMM-Upload-icon.png) no-repeat center 15px;
}
.adminMenu li a.menu3:hover, li.active a.menu3, .adminMenu li a.menu3:focus {
	background: #eef3f6 url(../images/PMM-Upload-iconHover.png) no-repeat center 15px;
	color: #003982;
}
.adminMenu li a.menu4 {
	background: #014aa7 url(../images/PMM-Reports-icon.png) no-repeat center 15px;
}
.adminMenu li a.menu4:hover, li.active a.menu4, .adminMenu li a.menu4:focus {
	background: #eef3f6 url(../images/PMM-Reports-iconHover.png) no-repeat center 15px;
	color: #003982;
}
.adminMenu li a.menu5 {
	background: #014aa7 url(../images/PMM-unlock-icon.png) no-repeat center 15px;
}
.adminMenu li a.menu5:hover, li.active a.menu5, .adminMenu li a.menu5:focus {
	background: #eef3f6 url(../images/PMM-unlock-iconHover.png) no-repeat center 15px;
	color: #003982;
}
.hdbottom p {
	color: #999;
	margin: -12px 0 10px 0;
	width: 35%;
}
.hdbottom select {
	width: 350px;
	margin: 0 0 25px 0;
	height: 32px;
}
/*	Potential Projects */

.PotentialPrj table tr td:nth-child(2) {
	background: #ddd;
}
/* Body Ends */


/* footer   */

footer {
	clear: both;
	/*margin: 15px -34px 0 -34px;*/
	border-top: solid 1px #ccc;
	padding: 9px 30px;
	height: 50px;
	background: #fff;
	position: sticky;
	width: 100%;
	bottom: 0;
	left: 0;
}
footer img {
	float: left;
}
footer p {
	float: right;
	font-size: 12px;
	padding-top: 7px;
}
footer p a {
	color: #4f4f4f;
}
/* footer Ends */



/* Typography */
.user a span, .content h5, .btns-assesment .btn span span, footer p, .dropdown-menu li, .panelset p, .adminMenu li a {
	font-family: "OpenSans-Regular", sans-serif;
}
.btns-assesment .btn b, footer p a, .innerpage-hd h2, .bodydata h3, h4, .badge, .ls p, .rs p, .AsmtData.data-boi .table td input[type=text], .popover-content, .infoblock span, .AsmtData.data-LeadingInd .table td input[type=text], .greentextB,  .redtextB {
	font-family: "OpenSans-Bold", sans-serif;
}
.btn-back, .form-control, .form-group label, .pagecounter li span, .infoblock p, .graphs h3 {
	font-family: "OpenSans-Semibold", sans-serif;
}
.content h1, .Graphik-Black-Web {
	font-family: 'Graphik Web', sans-serif;
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
}
.panelset h3, .Graphik-Bold-Web {
	font-family: 'Graphik Web', sans-serif;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}
.btn-danger, .Graphik-Medium-Web, .btn-group .btn, .btn-group-md .btn, .pmm-asmt-tbl .table th {
	font-family: 'Graphik Web', sans-serif;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}
.Graphik-Regular-Web, .graybtn, .pmm-asmt-tbl .table td {
	font-family: 'Graphik LC Web', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}
/* Custum form elements*/
/*Select list */
select.form-control {
	text-indent: 0.01px;
	text-overflow: '';
}
select.form-control::-ms-expand {
display: none;
}
select.form-control {
	-moz-appearance: none;
	-webkit-appearance: none;
	background: url("../images/list-arrow.png") no-repeat 97% 15px #fff;
	background-size: 10px auto !important;
}
.modelbodyrow select.form-control {
	background-position: 97% 15px;
}
/*Select list ends */

/*Checkbox The container */
.containerCk {
	display: inline;
	position: relative;
	padding-left: 35px;
	margin: 7px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* Hide the browser's default checkbox */
.containerCk input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	left: 0;
}
/* Create a custom checkbox */
.containerCk .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: solid 2px #ccc;
	border-radius: 3px;
}
/* On mouse-over, add a grey background color */
.containerCk:hover input ~ .checkmark {
	background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.containerCk input:checked ~ .checkmark {
	background-color: #2196F3;
	border: solid 1px #2196F3;
	border-radius: 3px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.containerCk .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
/* Show the checkmark when checked */
.containerCk input:checked ~ .checkmark:after {
	display: block;
}
/* Style the checkmark/indicator */
.containerCk .checkmark:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 13px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.configDropdown{
	width:20%;
	margin-bottom: 15px;
}

#root{
	height:100%;
}

div#root > div:first-child {
	height:100%;
}

.circleDiv{
	/* height: 20px;
	width: 20px;
	background-color: #d01a1a;
	border-radius: 50%; 
	margin-right: 8px; */

	height: 15px;
    width: 15px;
    background-color: #d01a1a;
    border-radius: 50%;
    margin-right: 8px;
    float: left;
    margin-top: 5px;
}

.circleDivCompare{	
	height: 15px;
    width: 15px;
    background-color: #d01a1a;
    border-radius: 50%;
	margin-right: 8px;
	margin-left: 10px;
    float: left;
    margin-top: 5px;
}

.infoblockfilter {
	background: #fff;
	box-shadow: 0 0 4px #ccc;
	min-width: 100px;
	height: 45px;
	border-radius: 4px;
	padding: 0 12px;
	/* margin-left: 10px; */
}
/* .infoblock  span {
	margin: 0;
	float: left;
	height: 45px;
	padding: 15px 10px 14px 0;
	border-right: solid 1px #ddd;
	font-size: 12px;
	color: #505a60;
} */

.infoblockfilter div {
	margin-top: 1px;
}

.avgSeperater{
	height: 45px;
	border-left: solid 1px #ddd
}

.avgseperatorPadding{
	padding-right: 10px !important;
}

.avgBlock{
	background: #fff;
	box-shadow: 0 0 4px #ccc;
	min-width: 100px;
	height: 75px;
	border-radius: 4px;
	padding: 0 12px;
	margin-left: 10px;
}
.avgBlock p{
	margin: 0;
	float: left;
	height: 20px;
	padding: 	5px 10px 14px 5px;
	border-right: solid 1px #ddd;
	font-size: 12px;
	color: #505a60;
	width:50%;
}
.avgBlock .title{
	margin: auto;
    width: 100%;
	border-bottom: solid 1px #ddd;
	
	/*padding: 5px 20px 5px 60px;*/
}
.avgBlock span{
	font-size: 20px;
	padding: 0px 10px 8px 10px;
	float: left;
	height: 25px;
}
.marginaround{
	margin:10px 0px;
}
/* .ag-header-cell-label {
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
} */

.ag-header-cell-label .ag-header-cell-text {
	white-space: normal !important;
  }

  .ag-cell{
	  white-space: normal !important;
  }

/* Average Table changes */
/* .table{
	margin-bottom: 0px;
} */

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
 padding:2px;
}

/* For Custom Legends in Charts*/
.ulStyle{
    text-align: center;
    font-family: "OpenSans-Regular", sans-serif;
	font-size: 13px;
	text-transform: none !important;
}

.liStyle{
    list-style-type: none;
    padding: 5px 0px;
}
.liStyle span{
    padding: 0px;
    border-radius: 1px;
}

.liStyle p{
    display: inline;
    margin: 0px 10px;
}


.barchartargin{
    margin-top: 30px !important;
}

/*.radarchartmargin{
    margin-top: 60px !important
}*/

.LIaddbutton{
    padding: 12px !important;
}

.chartMargins{
    margin: 3% 0px 1% !important;  
}

.chartMargins2{
    margin-top: 25px;
    margin-bottom: 25px;
    height: 700px;  
}

.chartMargins h4{
    text-align: center;
}


/*canvas{
    width: 500px;
    height: 420px;
}*/

#radarLegend{
    margin-bottom: 15px;
}

.barChartLegend{
	margin: 75% 0px;
	display: none;
}

.story-board-div{
	font-family: "OpenSans-Semibold", sans-serif !important;
	font-family: "OpenSans-Semibold", sans-serif !important;
} 
/* end of charts */

.LIaddbutton{
	padding: 12px !important;
}

.searchText{
	height: 35px;
    margin-top: 5px;
}

/*User Management*/
.innerpage-hd {
    height: 80px !important;
    background: #014aa7;
}

.adminMenu li a{
	height: 65px !important;
}

.multiple-tabs-section{
	padding: 20px 35px;
}

.multiple-tabs-section .saveDiv{
	margin-top: 10px;	
	width: 100%;
}

.saveDiv{
	margin-left: 0px !important;
}

.multiple-tabs-section .btn-group-md{
	float: right;
}

.multiple-tabs-section .control-label{
	text-align: left;
}

.multiple-tabs-section .nav-tabs{
	border-bottom: none;
	margin-bottom: 20px;
}

.multiple-tabs-section .nav-tabs li{
	text-transform: uppercase;
    color: #333;
    font-size: 13px;
}

.multiple-tabs-section .nav-tabs li a{
	text-transform: uppercase;
    color: #333;
    font-size: 13px;
}

.multiple-tabs-section .nav-tabs li a.active{
	border-bottom: 3px solid #61c75f;
    background: white;    
}

.multiple-tabs-section h6{
	font-weight: bold;
}

.multiple-tabs-section input{
	text-transform: capitalize;
}

.deleteIcon{
	padding: 5px 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    background: white;
    border-radius: 2px;
}

.editIcon{
	cursor: pointer;
}

.fleft{
    float: left;
}

.fright{
    float: right;
}

.searchBox{
    margin: 6px 0px;
    width: 25%;
    box-sizing: border-box;
    height: 36px;
    border-radius: 5px;
    border: 2px solid #ece6e694;
    padding: 3px;
}

#userDetailsDiv{
    margin-top: 10px;
}

/*Editable AGGrid Changes for PotentialProject Screen */
/*
.ag-cell[col-id=ShortlistProject], .ag-cell[col-id=ProjectDetails]{
    background: #cceecc8a;
}*/

/* .potential-project-div .ag-cell-inline-editing{
    background: #49ee498a;
} */

/* .potential-project-div .ag-cell[col-id=ShortlistProject],.potential-project-div .ag-cell[col-id=ProjectDetails],.potential-project-div .ag-cell[col-id=TimeMonths],.potential-project-div .ag-cell[col-id=ImpactOnBOI],.potential-project-div .ag-cell[col-id=Remarks]{
    background: #cceecc75;
}

.potential-project-div .ag-header-cell[col-id=ShortlistProject],.potential-project-div .ag-header-cell[col-id=ProjectDetails],.potential-project-div .ag-header-cell[col-id=TimeMonths],.potential-project-div .ag-header-cell[col-id=ImpactOnBOI],.potential-project-div .ag-header-cell[col-id=Remarks]{
    background: #cceecc75;
} */

/* .potential-project-div .ag-header-cell[col-id=ProjectId],.potential-project-div .ag-header-cell[col-id=LeverName],.potential-project-div .ag-header-cell[col-id=Project],.potential-project-div .ag-header-cell[col-id=Time],.potential-project-div .ag-header-cell[col-id=Impact]{
    background: #c1d9ef52;    
}

.potential-project-div .ag-cell[col-id=ProjectId],.potential-project-div .ag-cell[col-id=LeverName],.potential-project-div .ag-cell[col-id=Project],.potential-project-div .ag-cell[col-id=Time],.potential-project-div .ag-cell[col-id=Impact]{
    background: #c1d9ef52;
} */

.potential-project-div .ag-header-cell[col-id=ShortlistProject],.potential-project-div .ag-header-cell[col-id=ProjectDetails],.potential-project-div .ag-header-cell[col-id=TimeMonths],.potential-project-div .ag-header-cell[col-id=ImpactOnBOI],.potential-project-div .ag-header-cell[col-id=Remarks]{
	/*background: #c1d9ef52;    */
	background: #4990a052;
}

.potential-project-div .ag-cell[col-id=ShortlistProject],.potential-project-div .ag-cell[col-id=ProjectDetails],.potential-project-div .ag-cell[col-id=TimeMonths],.potential-project-div .ag-cell[col-id=ImpactOnBOI],.potential-project-div .ag-cell[col-id=Remarks]{
    /*background: #c1d9ef52;    */
	background: #4990a052;
}

.PMM-assessment-div .ag-header-cell[col-id=AsIsPMMScore],.PMM-assessment-div .ag-header-cell[col-id=TobePMMScore]{
    /*background: #c1d9ef52;    */
	background: #4990a052; 
}

.PMM-assessment-div .ag-cell[col-id=AsIsPMMScore],.PMM-assessment-div .ag-cell[col-id=TobePMMScore]{
    /*background: #c1d9ef52;    */
	background: #4990a052;
}

.BOI-div .ag-header-cell[col-id=DisplayName],.BOI-div .ag-header-cell[col-id=AsIs],.BOI-div .ag-header-cell[col-id=ToBe]{
    /*background: #c1d9ef52;    */
	background: #4990a052;  
}

.BOI-div .ag-cell[col-id=DisplayName],.BOI-div .ag-cell[col-id=AsIs],.BOI-div .ag-cell[col-id=ToBe]{
    /*background: #c1d9ef52;    */
	background: #4990a052;
}

.LI-div .ag-header-cell[col-id=DisplayName],.LI-div .ag-header-cell[col-id=AsIs],.LI-div .ag-header-cell[col-id=ToBe]{
    /*background: #c1d9ef52;    */
	background: #4990a052;   
}

.LI-div .ag-cell[col-id=DisplayName],.LI-div .ag-cell[col-id=AsIs],.LI-div .ag-cell[col-id=ToBe]{
    /*background: #c1d9ef52;    */
	background: #4990a052;
}

.custom-select .ag-cell[col-id=ConfigureBOIs], .custom-select .ag-cell[col-id=IndustrySpecific],.custom-select .ag-cell[col-id=Lever], .custom-select .ag-cell[col-id=KPA], .custom-select .ag-cell[col-id=MetricImpacted], .custom-select .ag-cell[col-id=BOIImpacted], .custom-select .ag-cell[col-id=MaturityElement]{
	overflow: visible !important;
	border: none !important;
}

.ag-cell[col-id=BOILIDefinition] br{
    /*background: #c1d9ef52;    */
    display: none !important;
}

.BOI-div .ag-cell[col-id=BOILIName], .boi-grid .ag-cell[col-id=BOILIName], .LI-view .ag-cell[col-id=BOILIName]{
    /*background: #c1d9ef52;    */
	cursor: pointer !important;
}

/* Editable Cell Changes Ends */

/*For loader */
#loader-div{
    position: fixed;
	/* z-index: 999; */
	z-index: 1051;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #2e2f2f36;
    display: none;
    width: 100%;
    height: 100%;
}

#loader-img{
    position: absolute;
    z-index: 999;
    top: 50%;
    right: 50%;
}

/*Loader changes ends */

#bubbleCanvasDiv{
	height: 500px;
	/*  width: 100%;*/
} 

/*Navigate Pop up changes */
.pagecounter li span.linkSpan{
	background: #4ec700 url(../images/icon-tick-white.png) no-repeat center center;
    font-size: 0;
    width: 34px;
    height: 34px;
    display: block;
    border-radius: 50%;
    margin-top: 0px;
    padding-top: 2px;
    color: #fff;
	border: solid 3px #c9d9ec;
	cursor: pointer;
}

.AsIsToBeLabel{
	font-size: 16px;
    font-weight: bold;
}

.cell-wrap-text {
    white-space: normal !important;
}


@media (min-width: 768px)
{
	.modal-dialog {
		/* width: 700px; */
		width: 900px;
		margin: 30px auto;
	}

	.modal-dialog-sc>.modal-dialog {
		/* width: 700px; */
		width: 1250px !important;
		margin: 30px auto;
	}
}

.statustxt-orng-italic{
	color: #d0851a;
    text-transform: uppercase;
	font-style: italic;
	cursor: context-menu;
}

/* Ag Grid Changes */
.ag-theme-material{
	font: 400 13px "OpenSans-Regular", sans-serif !important;
}

.ag-theme-material .ag-header{
	font: 700 12px "OpenSans-Semibold", sans-serif !important;
	color: #000 !important;
}

.ag-theme-material .ag-cell {
    line-height: 25px !important;
    border: 1px solid transparent;    
	padding: 0px 8px !important;	
	/* border-right: 1px solid #e0e0e0;	 */
}

.ag-theme-material .ag-cell-inline-editing select {
    padding: 12px 16px;
}

.ag-theme-material .ag-cell-inline-editing{
    height: inherit !important;
}

.ag-theme-material .ag-header-cell-label {
    width: auto;
}


.box-plain .ag-theme-material .ag-header-cell-label .ag-header-cell-text{
	line-height: 12px;
	padding: 5px 3px 3px 5px ;
	overflow: unset;
    text-overflow: inherit;
    white-space: nowrap;
}

.ag-theme-material .ag-header-cell-label .ag-header-cell-text:hover{
	overflow: visible;
}

.ag-theme-material .ag-row-odd{
	background: #d3d3d324;
}

.ag-theme-material .ag-row-hover {
    background: #cceeccb5 !important;
}

.ag-theme-material .ag-row-selected {
	background: #cceeccb5 !important;
}

.ag-layout-normal .ag-root {
    height: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
}

.ag-theme-material .ag-icon-menu, .ag-theme-material .ag-icon-desc,
.ag-theme-material .ag-icon-asc, .ag-theme-material .ag-icon-filter {
	background-position: top !important;
}

/*.ag-body-container, .ag-header-row{
	width: 100% !important;
}*/

.ag-theme-material.grid-div .ag-header-cell, .ag-theme-material.grid-div .ag-header-group-cell, .ag-theme-material.grid-div .ag-cell {
    padding: 0px 10px !important;
}

.ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell {    
	padding: 0px 2px !important;
}

.ag-theme-material .ag-header-select-all{
	margin-top: -15px;
}

.country-name-grid .ag-cell{
	word-break: break-all !important;
}

/*Master Screens*/
.grid-div{
	height: 500px;
	padding: 5px 15px;
}

.contact{
	padding-top: 13px;
	cursor: pointer;	
}

.contactHome{
	padding-top: 8px;
	cursor: pointer;
}

.contactHomeinnerpage{
	padding-top: 13px;
	cursor: pointer;
}

.contactHomeImg{
	height:24px;
	width:24px;
	margin-right: 10px;
}

.support{
	font-style: italic;
	color: #e69d3a;	
}

/* SVG Legend for Project Prioritization */
#bubbleLegend{
    font-size: 12px;
    font-family: "OpenSans-Semibold", sans-serif;
    text-align: center;
    margin-top: 7%;
}

#bubbleLegend p{
    margin: 0px;
}

.rotateCls{
    writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
}

.btn-group-md-master {
	margin: 20px 0 15px 0;
}

/*Master Mapping */
.search-div h6{
	font-weight: bold;
	margin-top: 25px;
}

.search-div {
	padding: 0px 15px;
}

.sel-country-div{
	height: 300px;
}

/* AutoSuggest CSS */

.react-autosuggest__container{
	width: 100%;
}

.react-autosuggest__input{
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 4px;
	height: 32px;
	padding: 6px 12px;
}

.react-autosuggest__input--focused{
	outline: none;
}
		
.react-autosuggest__input--open{
	border-radius: 4px 4px 0px 0px;
}

.react-autosuggest__suggestions-container{
	display: none;
}
          
.react-autosuggest__suggestions-container--open {
	display: block;
	z-index: 1000;
	position: absolute;
	width: 90%;
	max-height: 200px;
	overflow: auto;
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	box-sizing: border-box;
	border-radius: 0px 0px 4px 4px;
}

.react-autosuggest__suggestions-container--open ul{
	margin: 0px;
}

.react-autosuggest__suggestion{
	cursor:     pointer;
	padding:    10px 20px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #cceeccb5;
}

.react-autosuggest__suggestion-match {
	font-weight: bold;
}

/* AutoSuggest CSS */

.chkboxMargin{
	margin-left: 10px !important;
}

.innerpage-hd a, .multiple-tabs-section a{
	cursor: pointer;
}

.link{
	text-decoration: underline;
}

.mandatory-cls{
    color: red;
}

.grid-title{
	padding: 7px 0px;
}

#fUpload{
	display: inline-block;
}

.config-title{
	display: inline-block;
}

.Select-menu-outer {
	/*position: relative;
	z-index: 1001 !important;
	height:200px !important;*/
}

.disabled-grid{
	pointer-events: none;
	opacity: 0.5;
}

.upload-btn{
	width: 22%;
}

.error-span{
	background: #E57373;
}

.formula-span{
	background: #ffc200b8;	
}

.formula-span-gap{
	margin-right: 50px;	
}

.grid-legend{
	margin-top: 10px;
	margin-bottom: 0px;
}

.btn-downld{
	/* float: right !important; */
	border-radius: 10px !important;
}

.LIaddbutton {
    padding: 7px !important;
    /* float: right; */
    /* margin: 0px 10px; */
}

.pmmConfigAdd {
    padding: 7px !important;
	margin-bottom: 5px;
	font-family: 'Graphik Web', sans-serif;
}

.LIAdandDownload{
	display: flex;
    justify-content: flex-end;
}

.alignCenter{
	text-align: center;
}

.gdpr{
	margin: 0;
}

.compareHeader{
	margin-right: 5px;
	font-size: 13px;
    font-weight: bold;
}

.infoHeaderContent{
	font-style: italic;
	color: #e69d3a;	
	font-weight: bold;
}

.infoInnerContent{
	margin-left: 15px;
	/* color:#5bc0de; */
}

.infoTick{
	font-size: 0px;
    width: 27px;
    height: 27px;
    display: inline-flex;
    margin-top: -5px;
    padding-top: 2px;
    color: rgb(255, 255, 255);
    background: url(../images/icon-tick-white.png) center center no-repeat rgb(78, 199, 0);
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: rgb(201, 217, 236);
    border-image: initial;
}

.footerPosition{
	position: fixed;
	z-index: 1010 !important;
}

.mousePointer{
	cursor: pointer;
}

.startAssessmentbtn{
	margin-bottom: auto;
}

.infoRole{
	font-size: 13px !important;
	color: #e69d3a;	
	font-weight: bold;
	text-align: left !important;
}

.downloadComparative{
	margin-top: -7px;
	margin-left: 10px;
}

.infoCompare{
	margin-top: 5px;
	margin-left: 5px;
	color: #e69d3a;	
	font-weight: bold;
}

.downloadCommon{
	border-radius: 5px !important;
    margin-left: 2px;
    margin-right: 2px;
}

.btn-popup {
	background: #3074ca;
	border-radius: 20px;
	float: left;
	margin-right: 10px;
	border-color: transparent;
	font-size: 13px;
	font-family: "OpenSans-Semibold", sans-serif;
}

.padding5{
	padding: 5px 5px 5px 5px;
}

.row-margin{
	margin: 5px 0px 0px 0px !important;
}

.nopadding{
	padding-left: 0px;
}

.deal-mapping-chkbox{
	margin: 0px 20px 0px 20px !important;
}


/* Deal Mapping Checkbox */

.form-checkbox
{
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     position: relative;
     background-color: #337ab7;
     color: #666;
     top: 10px;
     height: 20px;
     width: 20px;
     border: 0;
     border-radius: 3px;
     cursor: pointer;     
     margin: 0px 20px 5px 20px !important;
     outline: none;
}
.form-checkbox:checked::before
{
     position: absolute;
     font: 13px/1 'Open Sans', sans-serif;
     left: 6px;
     top: 3px;
     content: '\02143';
	 transform: rotate(40deg);
	 font-weight: bold;
	 color: #FFF;
}
.form-checkbox:hover
{
     background-color: #b5f0b0;
}
.form-checkbox:checked
{
     background-color: #b5f0b0;
}
label
{
     font: 300 16px/1.7 'Open Sans', sans-serif;
     color: #666;
     cursor: pointer;
} 

.no-padding-lr {
	padding: 0px;
}

.common-btn{
	border-radius: 20px;
    margin-right: 5px;
    width: 85px;
    font-weight: bold;
}

.modal-dialog-sc>.ag-body-viewport{
	overflow-y: auto !important;
	overflow-x: hidden !important;
}

/* .react-datepicker__month-container {
    float: left;
    width: 240px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #000 !important;
    display: inline-block !important;
    width: 2.7rem !important;
    line-height: 2.7rem !important;
    text-align: center !important;
    margin: 0.166rem !important;
} */

.ag-row-focus{
	z-index: 1000 !important;
}

.Select-menu-outer{
	z-index: 1001 !important;
}

.ag-theme-material .ag-popup-editor {
    border-radius: 2px  !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)  !important;
    background: #f5f5f5  !important;
    padding: 0  !important;
	z-index: 1000  !important;
}

.btn-orange{
	background-color: #f7701e;
}

.btn-lightblue{
	background-color: #5bc0de;
}

.red-text{
	color: red;
}

.upload-select{
	margin-top: 10px;
}

.note{
	font-family: "OpenSans-Regular", sans-serif;
    text-transform: none;
    color: #328cfd;
}

.pmm-logo > img {
    height: 50px;
}

.storyBoardBox{
    
    box-shadow: 0 0 5px #ccc;
    background: #fff;
    min-height: 85px;
    float: left;
    border-radius: 5px;
    margin-bottom: 5px;
}
.storyboardgrid .ag-header-cell{
    border: 1px solid lightgray !important;
 
}

.assessmentaidTableth{
	table-layout: fixed;
	padding: 5px;
	border: 1px solid #ddd;
}

.assessmentaidTableheader{
table-layout: fixed;
padding: 5px;
padding-right: 330px;
border: 1px solid #ddd;
text-align: center;
}


.btn-group-md .btn-graph {
	background:#d13c78;
	color:#FFF;
}

.std-span{
	background: #5ab4d8;
}
.mousePointerSwitchAccounts{
	cursor: pointer;
	border-radius: 10px;
	background-color:#f7701e;
	color: white !important;
}
.mousePointerSwitchLanguage{
	cursor: pointer;
	border-radius: 10px;
	background-color:#187eff;
	color: white !important;
}
#visible:hover .mousePointerSwitchLanguage {
	cursor: pointer;
	border-radius: 10px;
	background-color:#f7701e;
	color: white !important;
}
.groupName{
	font-size: medium;
	font-family: "OpenSans-Regular", sans-serif;
}
#hidden {
	display: none;
	/* position: absolute; */
	/* left: 150px; */
	/* background: white; */
	/* border-radius: 4px; */
	/* top: -8px; */
	/* min-width: 90px;  */
  }
#visible:hover #hidden {
	display: block;
	cursor: pointer;
}
#visible:hover .mousePointerSwitchAccounts {
	cursor: pointer;
	border-radius: 10px;
	background-color:#187eff;
	color: white !important;
}
#hidden li a {
	width: 80%;
	border-bottom: solid 1px #ccc;
	padding-left: 10px;
	border-radius: 10px;
	/* background-color:#f7701e; */
	/* color: white !important; */
	padding-bottom: 2px;
	padding-top: 2px;
}
#hidden li {
	float: left;
	clear: both;
	width: 100%;
}

.arrow {
	border: solid #666;
	border-width: 0 1.2px 1.2px 0;
	display: inline-block;
	margin-bottom: 0.5px;
	padding: 3px;
  }
  
  .right {
	-webkit-transform: rotate(-45deg);
  }

  .switchModal{
	top: 20%;
}
  .switchModal .modal-body{
	  background: #ffffff url("../images/home-Bg.png") no-repeat right;
	  background-size: 656px;
  }

.dropdown-item {
	display: block;
	width: 100%;
	padding: .5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #c7d6e8;
}

.dropdown-menu {
    position: absolute; 
    top: 100%;
    left: 0; 
    z-index: 1000;
    display: none; 
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    /* font-size: 1rem; */
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

