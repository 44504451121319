/* OpenSans */

@font-face {
    font-family: "OpenSans-Regular";
     src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), 
       url("../fonts/OpenSans-Regular.woff") format("woff"), 
       url("../fonts/OpenSans-Regular.ttf")  format("truetype"),
       url("../fonts/OpenSans-Regular.svg#svgFontName") format("svg");
}
@font-face {
    font-family: "OpenSans-Light";
    src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), 
       url("../fonts/OpenSans-Light.woff") format("woff"), 
       url("../fonts/OpenSans-Light.ttf")  format("truetype"),
       url("../fonts/OpenSans-Light.svg#svgFontName") format("svg");
}
@font-face {
    font-family: "OpenSans-Bold";
    src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), 
       url("../fonts/OpenSans-Bold.woff") format("woff"), 
       url("../fonts/OpenSans-Bold.ttf")  format("truetype"),
       url("../fonts/OpenSans-Bold.svg#svgFontName") format("svg");
}
@font-face {
    font-family: "OpenSans-Semibold";
    src: url("../fonts/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), 
       url("../fonts/OpenSans-Semibold.woff") format("woff"), 
       url("../fonts/OpenSans-Semibold.ttf")  format("truetype"),
       url("../fonts/OpenSans-Semibold.svg#svgFontName") format("svg");
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?97qard');
  src:  url('../fonts/icomoon.eot?97qard#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?97qard') format('truetype'),
    url('../fonts/icomoon.woff?97qard') format('woff'),
    url('../fonts/icomoon.svg?97qard#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Lato-Light";
  src: url("../fonts/Lato-Light.eot?#iefix") format("embedded-opentype"), 
    
       url("../fonts/Lato-Light.ttf")  format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src: url("../fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), 
     
       url("../fonts/Lato-Regular.ttf")  format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  src: url("../fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), 
     
       url("../fonts/Lato-Bold.ttf")  format("truetype");
}

@font-face {
  font-family: "rotissansserif";
  src: url("../fonts/agfarotissansserif.eot?#iefix") format("embedded-opentype"), 
       url("../fonts/agfarotissansserif.woff") format("woff"), 
       url("../fonts/agfarotissansserif.ttf")  format("truetype"),
       url("../fonts/agfarotissansserif.svg#svgFontName") format("svg");
}

/*===========================================================================*/

/* GRAPHIC */
@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/../fonts/Graphik-SuperItalic-Web.eot');
  src: url('../fonts/Graphik-SuperItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-SuperItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-SuperItalic-Web.woff') format('woff');
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-Super-Web.eot');
  src: url('../fonts/Graphik-Super-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Super-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Super-Web.woff') format('woff');
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-BlackItalic-Web.eot');
  src: url('../fonts/Graphik-BlackItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-BlackItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-BlackItalic-Web.woff') format('woff');
  font-weight:  800;
  font-style:   italic;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Black-Web.eot');
  src: url('../fonts/Graphik-Black-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Black-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Black-Web.woff') format('woff');
  font-weight:  800;
  font-style:   normal;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-BoldItalic-Web.eot');
  src: url('../fonts/Graphik-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-BoldItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-BoldItalic-Web.woff') format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Bold-Web.eot');
  src: url('../fonts/Graphik-Bold-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Bold-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Bold-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-SemiboldItalic-Web.eot');
  src: url('../fonts/Graphik-SemiboldItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
  font-weight:  600;
  font-style:   italic;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-Semibold-Web.eot');
  src: url('../fonts/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Semibold-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-MediumItalic-Web.eot');
  src: url('../fonts/Graphik-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Medium-Web.eot');
  src: url('../fonts/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Medium-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-RegularItalic-Web.eot');
  src: url('../fonts/Graphik-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-Regular-Web.eot');
  src: url('../fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Regular-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}





@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-LightItalic-Web.eot');
  src: url('../fonts/Graphik-LightItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-LightItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-LightItalic-Web.woff') format('woff');
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
}




@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Light-Web.eot');
  src: url('../fonts/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Light-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Light-Web.woff') format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-ExtralightItalic-Web.eot');
  src: url('../fonts/Graphik-ExtralightItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-ExtralightItalic-Web.woff') format('woff');
  font-weight:  200;
  font-style:   italic;
  font-stretch: normal;
}





@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Extralight-Web.eot');
  src: url('../fonts/Graphik-Extralight-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Extralight-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Extralight-Web.woff') format('woff');
  font-weight:  200;
  font-style:   normal;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-ThinItalic-Web.eot');
  src: url('../fonts/Graphik-ThinItalic-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-ThinItalic-Web.woff2') format('woff2'),
       url('../fonts/Graphik-ThinItalic-Web.woff') format('woff');
  font-weight:  100;
  font-style:   italic;
  font-stretch: normal;
}



@font-face {
  font-family: 'Graphik LC Web';
  src: url('../fonts/Graphik-Thin-Web.eot');
  src: url('../fonts/Graphik-Thin-Web.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Graphik-Thin-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Thin-Web.woff') format('woff');
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
}

/*====================================================================*/


.Graphik-SuperItalic-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}
.Graphik-Super-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}
.Graphik-BlackItalic-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  800;
  font-style:   italic;
  font-stretch: normal;
}
.Graphik-Black-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  800;
  font-style:   normal;
  font-stretch: normal;
}
.Graphik-BoldItalic-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}
.Graphik-Bold-Web  {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}
.Graphik-SemiboldItalic-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  600;
  font-style:   italic;
  font-stretch: normal;
}

.Graphik-Semibold-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

.Graphik-MediumItalic-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}
.Graphik-Medium-Web  {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}
.Graphik-RegularItalic-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}

.Graphik-Regular-Web  {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


.Graphik-LightItalic-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
}

.Graphik-Light-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

.Graphik-ExtralightItalic-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  200;
  font-style:   italic;
  font-stretch: normal;
}

.Graphik-Extralight-Web {
  font-family: 'Graphik Web', sans-serif;
  font-weight:  200;
  font-style:   normal;
  font-stretch: normal;
}


.Graphik-ThinItalic-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  100;
  font-style:   italic;
  font-stretch: normal;
}
.Graphik-Thin-Web {
  font-family: 'Graphik LC Web', sans-serif;
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
}

 
